import _styled from "../../../_snowpack/pkg/@emotion/styled.js";
import React from "../../../_snowpack/pkg/react.js";
import { Link } from "../../../_snowpack/pkg/react-router-dom.js";
import Brand from "../../images/Brand.js";
import ToggleDark from "./ToggleDark.js";
import MobileMenu from "./MobileMenu.js";
import UserMenu from "./UserMenu.js";
/**
 * Top Navigation Bar
 *
 * @returns {JSX.Element} returns the top navigation bar
 */

import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const TopNavBar = () => {
  const TopNavBar = _styled.header({
    "flexShrink": "0",
    "position": "relative",
    "height": "4rem",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
    ".dark &": {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))"
    },
    "display": "flex",
    "alignItems": "center"
  });

  const BrandBox = _styled.div({
    "position": "absolute",
    "top": "0px",
    "bottom": "0px",
    "left": "0px",
    "@media (min-width: 768px)": {
      "position": "static",
      "flexShrink": "0"
    }
  });

  const DesktopNav = _styled.div({
    "display": "none",
    "@media (min-width: 768px)": {
      "minWidth": "0px",
      "flex": "1 1 0%",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between"
    }
  });

  return __cssprop(TopNavBar, {
    id: "top-nav-bar"
  }, __cssprop(BrandBox, {
    id: "brand-box"
  }, __cssprop(Link, {
    to: "/",
    css: {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "height": "4rem",
      "width": "5rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-inset": "inset",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop(Brand, {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(53, 62, 71, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
      },
      "height": "4rem",
      "width": "auto"
    },
    role: "img"
  }))), __cssprop(DesktopNav, {
    id: "desktop-nav"
  }, __cssprop("div", {
    css: {
      "minWidth": "0px",
      "flex": "1 1 0%"
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "42rem",
      "position": "relative",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ":focus-within": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
      },
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))",
        ":focus-within": {
          "--tw-text-opacity": "1",
          "color": "rgba(229, 231, 235, var(--tw-text-opacity))"
        }
      }
    }
  }, __cssprop("label", {
    htmlFor: "search",
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "search"), __cssprop("input", {
    id: "search",
    type: "search",
    placeholder: "search",
    css: {
      "display": "block",
      "width": "100%",
      "borderColor": "transparent",
      "backgroundColor": "transparent",
      "paddingLeft": "3rem",
      "::placeholder": {
        "--tw-placeholder-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-placeholder-opacity))"
      },
      ":focus": {
        "borderColor": "transparent",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)"
      },
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  }), __cssprop("div", {
    css: {
      "pointerEvents": "none",
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "left": "0px",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "paddingLeft": "1rem"
    }
  }, __cssprop("svg", {
    "x-heroicon": "outline/search",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    css: {
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(243, 244, 246, var(--tw-text-opacity))"
      }
    }
  }, __cssprop("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 2,
    d: "M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
  }))))), __cssprop("div", {
    css: {
      "marginLeft": "2.5rem",
      "paddingRight": "1rem",
      "flexShrink": "0",
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(2.5rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop("nav", {
    "aria-label": "Global",
    css: {
      "display": "flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(2.5rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(2.5rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(Link, {
    to: "/",
    css: {
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500"
    }
  }, "Profile"), __cssprop(Link, {
    to: "/marketing",
    css: {
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500"
    }
  }, "Marketing"), __cssprop(Link, {
    to: "/support",
    css: {
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500"
    }
  }, "Support")), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(2rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(2rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    }
  }, __cssprop(ToggleDark, null), __cssprop(UserMenu, null)))), __cssprop(MobileMenu, {
    id: "mobile-menu",
    css: {
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "right": "0px",
      "paddingRight": "1rem",
      "display": "flex",
      "alignItems": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(1rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(1rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "@media (min-width: 640px)": {
        "paddingRight": "1.5rem"
      },
      "@media (min-width: 768px)": {
        "display": "none"
      }
    }
  }));
};

export default TopNavBar;