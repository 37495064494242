import { css as _css } from "../../../_snowpack/pkg/@emotion/react.js";
import React from "../../../_snowpack/pkg/react.js";
import { CreditCardIcon, KeyIcon, UserCircleIcon, UserGroupIcon, ViewGridAddIcon } from "../../../_snowpack/pkg/@heroicons/react/outline.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";
const navigation = [{
  name: "Account",
  href: "#",
  icon: UserCircleIcon,
  current: true
}, {
  name: "Password",
  href: "#",
  icon: KeyIcon,
  current: false
}, {
  name: "Plan & Billing",
  href: "#",
  icon: CreditCardIcon,
  current: false
}, {
  name: "Team",
  href: "#",
  icon: UserGroupIcon,
  current: false
}, {
  name: "Integrations",
  href: "#",
  icon: ViewGridAddIcon,
  current: false
}];
export default function UserSettings() {
  return __cssprop("div", {
    css: {
      "padding": "0.5rem",
      "@media (min-width: 1024px)": {
        "display": "grid",
        "gridTemplateColumns": "repeat(12, minmax(0, 1fr))",
        "columnGap": "1.25rem"
      }
    }
  }, __cssprop("aside", {
    css: {
      "paddingTop": "1.5rem",
      "paddingBottom": "1.5rem",
      "paddingLeft": "0.5rem",
      "paddingRight": "0.5rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem"
      },
      "@media (min-width: 1024px)": {
        "gridColumn": "span 3 / span 3",
        "paddingTop": "0px",
        "paddingBottom": "0px",
        "paddingLeft": "0px",
        "paddingRight": "0px"
      }
    }
  }, __cssprop("nav", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.25rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.25rem * var(--tw-space-y-reverse))"
      }
    }
  }, navigation.map(item => __cssprop("a", {
    key: item.name,
    href: item.href,
    className: "group",
    css: _css(item.current ? {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "--tw-text-opacity": "1",
      "color": "rgba(253, 181, 21, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(253, 181, 21, var(--tw-text-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))"
      }
    } : {
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      }
    }, {
      "borderRadius": "0.375rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "display": "flex",
      "alignItems": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500"
    }),
    "aria-current": item.current ? "page" : undefined
  }, __cssprop(item.icon, {
    css: _css(item.current ? {
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
      ".group:hover &": {
        "--tw-text-opacity": "1",
        "color": "rgba(88, 200, 223, var(--tw-text-opacity))"
      }
    } : {
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ".group:hover &": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
      }
    }, {
      "flexShrink": "0",
      "marginLeft": "-0.25rem",
      "marginRight": "0.75rem",
      "height": "1.5rem",
      "width": "1.5rem"
    }),
    "aria-hidden": "true"
  }), __cssprop("span", {
    css: {
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap"
    }
  }, item.name))))), __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.5rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.5rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem"
      },
      "@media (min-width: 1024px)": {
        "gridColumn": "span 9 / span 9",
        "paddingLeft": "0px",
        "paddingRight": "0px"
      }
    }
  }, __cssprop("form", {
    action: "#",
    method: "POST"
  }, __cssprop("div", {
    css: {
      "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "@media (min-width: 640px)": {
        "borderRadius": "0.375rem",
        "overflow": "hidden"
      }
    }
  }, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingTop": "1.5rem",
      "paddingBottom": "1.5rem",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.5rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.5rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "padding": "1.5rem"
      }
    }
  }, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.125rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, "Profile"), __cssprop("p", {
    css: {
      "marginTop": "0.25rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "This information will be displayed publicly so be careful what you share.")), __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(3, minmax(0, 1fr))",
      "gap": "1.5rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3",
      "@media (min-width: 640px)": {
        "gridColumn": "span 2 / span 2"
      }
    }
  }, __cssprop("label", {
    htmlFor: "company_website",
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Username"), __cssprop("div", {
    css: {
      "marginTop": "0.25rem",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "display": "flex"
    }
  }, __cssprop("span", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "borderWidth": "1px",
      "borderRightWidth": "0px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderTopLeftRadius": "0.375rem",
      "borderBottomLeftRadius": "0.375rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      "display": "inline-flex",
      "alignItems": "center",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  }, "workcation.com/"), __cssprop("input", {
    type: "text",
    name: "username",
    id: "username",
    autoComplete: "username",
    css: {
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))"
      },
      "flexGrow": "1",
      "display": "block",
      "width": "100%",
      "minWidth": "0px",
      "borderRadius": "0px",
      "borderTopRightRadius": "0.375rem",
      "borderBottomRightRadius": "0.375rem",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  }))), __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop("label", {
    htmlFor: "about",
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "About"), __cssprop("div", {
    css: {
      "marginTop": "0.25rem"
    }
  }, __cssprop("textarea", {
    id: "about",
    name: "about",
    rows: 3,
    css: {
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))"
      },
      "marginTop": "0.25rem",
      "display": "block",
      "width": "100%",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.375rem",
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    },
    placeholder: "you@example.com",
    defaultValue: ""
  })), __cssprop("p", {
    css: {
      "marginTop": "0.5rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "Brief description for your profile. URLs are hyperlinked.")), __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop("label", {
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Photo"), __cssprop("div", {
    css: {
      "marginTop": "0.25rem",
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("span", {
    css: {
      "display": "inline-block",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))",
      "borderRadius": "9999px",
      "overflow": "hidden",
      "height": "3rem",
      "width": "3rem"
    }
  }, __cssprop("svg", {
    css: {
      "height": "100%",
      "width": "100%",
      "--tw-text-opacity": "1",
      "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
    },
    fill: "currentColor",
    viewBox: "0 0 24 24"
  }, __cssprop("path", {
    d: "M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
  }))), __cssprop("button", {
    type: "button",
    css: {
      "marginLeft": "1.25rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      "fontSize": "0.875rem",
      "lineHeight": "1rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      }
    }
  }, "Change"))), __cssprop("div", {
    css: {
      "gridColumn": "span 3 / span 3"
    }
  }, __cssprop("label", {
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Cover photo"), __cssprop("div", {
    css: {
      "marginTop": "0.25rem",
      "borderWidth": "2px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderStyle": "dashed",
      "borderRadius": "0.375rem",
      "paddingLeft": "1.5rem",
      "paddingRight": "1.5rem",
      "paddingTop": "1.25rem",
      "paddingBottom": "1.5rem",
      "display": "flex",
      "justifyContent": "center"
    }
  }, __cssprop("div", {
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.25rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.25rem * var(--tw-space-y-reverse))"
      },
      "textAlign": "center"
    }
  }, __cssprop("svg", {
    css: {
      "marginLeft": "auto",
      "marginRight": "auto",
      "height": "3rem",
      "width": "3rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    stroke: "currentColor",
    fill: "none",
    viewBox: "0 0 48 48",
    "aria-hidden": "true"
  }, __cssprop("path", {
    d: "M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02",
    strokeWidth: 2,
    strokeLinecap: "round",
    strokeLinejoin: "round"
  })), __cssprop("div", {
    css: {
      "display": "flex",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))"
    }
  }, __cssprop("label", {
    htmlFor: "file-upload",
    css: {
      "position": "relative",
      "cursor": "pointer",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderRadius": "0.375rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(88, 200, 223, var(--tw-text-opacity))"
      },
      ":focus-within": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop("span", null, "Upload a file"), __cssprop("input", {
    id: "file-upload",
    name: "file-upload",
    type: "file",
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  })), __cssprop("p", {
    css: {
      "paddingLeft": "0.25rem"
    }
  }, "or drag and drop")), __cssprop("p", {
    css: {
      "fontSize": "0.75rem",
      "lineHeight": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "PNG, JPG, GIF up to 10MB")))))), __cssprop("div", {
    css: {
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.75rem",
      "paddingBottom": "0.75rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "textAlign": "right",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem"
      }
    }
  }, __cssprop("button", {
    type: "submit",
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(88, 200, 223, var(--tw-bg-opacity))",
      "borderWidth": "1px",
      "borderColor": "transparent",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "display": "inline-flex",
      "justifyContent": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "0.9"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      }
    }
  }, "Save")))), __cssprop("form", {
    action: "#",
    method: "POST"
  }, __cssprop("div", {
    css: {
      "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "@media (min-width: 640px)": {
        "borderRadius": "0.375rem",
        "overflow": "hidden"
      }
    }
  }, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingTop": "1.5rem",
      "paddingBottom": "1.5rem",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.5rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.5rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "padding": "1.5rem"
      }
    }
  }, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.125rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, "Personal Information"), __cssprop("p", {
    css: {
      "marginTop": "0.25rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "Use a permanent address where you can recieve mail.")), __cssprop("div", {
    css: {
      "display": "grid",
      "gridTemplateColumns": "repeat(6, minmax(0, 1fr))",
      "gap": "1.5rem"
    }
  }, __cssprop("div", {
    css: {
      "gridColumn": "span 6 / span 6",
      "@media (min-width: 640px)": {
        "gridColumn": "span 3 / span 3"
      }
    }
  }, __cssprop("label", {
    htmlFor: "first_name",
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "First name"), __cssprop("input", {
    type: "text",
    name: "first_name",
    id: "first_name",
    autoComplete: "given-name",
    css: {
      "marginTop": "0.25rem",
      "display": "block",
      "width": "100%",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 6 / span 6",
      "@media (min-width: 640px)": {
        "gridColumn": "span 3 / span 3"
      }
    }
  }, __cssprop("label", {
    htmlFor: "last_name",
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Last name"), __cssprop("input", {
    type: "text",
    name: "last_name",
    id: "last_name",
    autoComplete: "family-name",
    css: {
      "marginTop": "0.25rem",
      "display": "block",
      "width": "100%",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 6 / span 6",
      "@media (min-width: 640px)": {
        "gridColumn": "span 4 / span 4"
      }
    }
  }, __cssprop("label", {
    htmlFor: "email_address",
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Email address"), __cssprop("input", {
    type: "text",
    name: "email_address",
    id: "email_address",
    autoComplete: "email",
    css: {
      "marginTop": "0.25rem",
      "display": "block",
      "width": "100%",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 6 / span 6",
      "@media (min-width: 640px)": {
        "gridColumn": "span 3 / span 3"
      }
    }
  }, __cssprop("label", {
    htmlFor: "country",
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Country / Region"), __cssprop("select", {
    id: "country",
    name: "country",
    autoComplete: "country",
    css: {
      "marginTop": "0.25rem",
      "display": "block",
      "width": "100%",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  }, __cssprop("option", null, "United States"), __cssprop("option", null, "Canada"), __cssprop("option", null, "Mexico"))), __cssprop("div", {
    css: {
      "gridColumn": "span 6 / span 6"
    }
  }, __cssprop("label", {
    htmlFor: "street_address",
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Street address"), __cssprop("input", {
    type: "text",
    name: "street_address",
    id: "street_address",
    autoComplete: "street-address",
    css: {
      "marginTop": "0.25rem",
      "display": "block",
      "width": "100%",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 6 / span 6",
      "@media (min-width: 640px)": {
        "gridColumn": "span 6 / span 6"
      },
      "@media (min-width: 1024px)": {
        "gridColumn": "span 2 / span 2"
      }
    }
  }, __cssprop("label", {
    htmlFor: "city",
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "City"), __cssprop("input", {
    type: "text",
    name: "city",
    id: "city",
    css: {
      "marginTop": "0.25rem",
      "display": "block",
      "width": "100%",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 6 / span 6",
      "@media (min-width: 640px)": {
        "gridColumn": "span 3 / span 3"
      },
      "@media (min-width: 1024px)": {
        "gridColumn": "span 2 / span 2"
      }
    }
  }, __cssprop("label", {
    htmlFor: "state",
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "State / Province"), __cssprop("input", {
    type: "text",
    name: "state",
    id: "state",
    css: {
      "marginTop": "0.25rem",
      "display": "block",
      "width": "100%",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  })), __cssprop("div", {
    css: {
      "gridColumn": "span 6 / span 6",
      "@media (min-width: 640px)": {
        "gridColumn": "span 3 / span 3"
      },
      "@media (min-width: 1024px)": {
        "gridColumn": "span 2 / span 2"
      }
    }
  }, __cssprop("label", {
    htmlFor: "postal_code",
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "ZIP / Postal"), __cssprop("input", {
    type: "text",
    name: "postal_code",
    id: "postal_code",
    autoComplete: "postal-code",
    css: {
      "marginTop": "0.25rem",
      "display": "block",
      "width": "100%",
      "borderWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "0.875rem",
        "lineHeight": "1.25rem"
      }
    }
  })))), __cssprop("div", {
    css: {
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.75rem",
      "paddingBottom": "0.75rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "textAlign": "right",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem"
      }
    }
  }, __cssprop("button", {
    type: "submit",
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(88, 200, 223, var(--tw-bg-opacity))",
      "borderWidth": "1px",
      "borderColor": "transparent",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "display": "inline-flex",
      "justifyContent": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "0.9"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      }
    }
  }, "Save")))), __cssprop("form", {
    action: "#",
    method: "POST"
  }, __cssprop("div", {
    css: {
      "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "@media (min-width: 640px)": {
        "borderRadius": "0.375rem",
        "overflow": "hidden"
      }
    }
  }, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "paddingTop": "1.5rem",
      "paddingBottom": "1.5rem",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1.5rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1.5rem * var(--tw-space-y-reverse))"
      },
      "@media (min-width: 640px)": {
        "padding": "1.5rem"
      }
    }
  }, __cssprop("div", null, __cssprop("h3", {
    css: {
      "fontSize": "1.125rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, "Notifications"), __cssprop("p", {
    css: {
      "marginTop": "0.25rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "Provide basic informtion about the job. Be specific with the job title.")), __cssprop("fieldset", null, __cssprop("legend", {
    css: {
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, "By Email"), __cssprop("div", {
    css: {
      "marginTop": "1rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "flex-start"
    }
  }, __cssprop("div", {
    css: {
      "height": "1.25rem",
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("input", {
    id: "comments",
    name: "comments",
    type: "checkbox",
    css: {
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      },
      "height": "1rem",
      "width": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.25rem"
    }
  })), __cssprop("div", {
    css: {
      "marginLeft": "0.75rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem"
    }
  }, __cssprop("label", {
    htmlFor: "comments",
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Comments"), __cssprop("p", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "Get notified when someones posts a comment on a posting."))), __cssprop("div", null, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "flex-start"
    }
  }, __cssprop("div", {
    css: {
      "height": "1.25rem",
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("input", {
    id: "candidates",
    name: "candidates",
    type: "checkbox",
    css: {
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      },
      "height": "1rem",
      "width": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.25rem"
    }
  })), __cssprop("div", {
    css: {
      "marginLeft": "0.75rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem"
    }
  }, __cssprop("label", {
    htmlFor: "candidates",
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Candidates"), __cssprop("p", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "Get notified when a candidate applies for a job.")))), __cssprop("div", null, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "flex-start"
    }
  }, __cssprop("div", {
    css: {
      "height": "1.25rem",
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("input", {
    id: "offers",
    name: "offers",
    type: "checkbox",
    css: {
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      },
      "height": "1rem",
      "width": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      "borderRadius": "0.25rem"
    }
  })), __cssprop("div", {
    css: {
      "marginLeft": "0.75rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem"
    }
  }, __cssprop("label", {
    htmlFor: "offers",
    css: {
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Offers"), __cssprop("p", {
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "Get notified when a candidate accepts or rejects an offer.")))))), __cssprop("fieldset", {
    css: {
      "marginTop": "1.5rem"
    }
  }, __cssprop("legend", {
    css: {
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, "Push Notifications"), __cssprop("p", {
    css: {
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
    }
  }, "These are delivered via SMS to your mobile phone."), __cssprop("div", {
    css: {
      "marginTop": "1rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(1rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(1rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("input", {
    id: "push_everything",
    name: "push_notifications",
    type: "radio",
    css: {
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      },
      "height": "1rem",
      "width": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))"
    }
  }), __cssprop("label", {
    htmlFor: "push_everything",
    css: {
      "marginLeft": "0.75rem"
    }
  }, __cssprop("span", {
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Everything"))), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("input", {
    id: "push_email",
    name: "push_notifications",
    type: "radio",
    css: {
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      },
      "height": "1rem",
      "width": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))"
    }
  }), __cssprop("label", {
    htmlFor: "push_email",
    css: {
      "marginLeft": "0.75rem"
    }
  }, __cssprop("span", {
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "Same as email"))), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("input", {
    id: "push_nothing",
    name: "push_notifications",
    type: "radio",
    css: {
      ":focus": {
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      },
      "height": "1rem",
      "width": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))"
    }
  }), __cssprop("label", {
    htmlFor: "push_nothing",
    css: {
      "marginLeft": "0.75rem"
    }
  }, __cssprop("span", {
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    }
  }, "No push notifications")))))), __cssprop("div", {
    css: {
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.75rem",
      "paddingBottom": "0.75rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
      "textAlign": "right",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem"
      }
    }
  }, __cssprop("button", {
    type: "submit",
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(88, 200, 223, var(--tw-bg-opacity))",
      "borderWidth": "1px",
      "borderColor": "transparent",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "display": "inline-flex",
      "justifyContent": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      ":hover": {
        "--tw-bg-opacity": "0.9"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      }
    }
  }, "Save"))))));
}