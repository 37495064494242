import _styled from "../../../_snowpack/pkg/@emotion/styled.js";
import React, { useEffect } from "../../../_snowpack/pkg/react.js";
import useAuth from "../../hooks/AuthHooks.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const Item = _styled.li({
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  ".dark &": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))"
  },
  "overflow": "hidden",
  "@media (min-width: 640px)": {
    "borderRadius": "0.5rem",
    "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
  }
});

const DetailItem = ({
  title,
  titleIcon,
  titleText,
  pill,
  pillIcon,
  pillText,
  css,
  children
}) => __cssprop("li", {
  css: css,
  className: "Dealer-details-general"
}, __cssprop("div", {
  css: {
    "display": "block",
    ":hover": {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))"
    },
    ".dark &": {
      ":hover": {
        "--tw-bg-opacity": "0.5",
        "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))"
      }
    }
  }
}, __cssprop("div", {
  css: {
    "paddingLeft": "1rem",
    "paddingRight": "1rem",
    "paddingTop": "1rem",
    "paddingBottom": "1rem",
    "@media (min-width: 640px)": {
      "paddingLeft": "1.5rem",
      "paddingRight": "1.5rem"
    }
  }
}, __cssprop("div", {
  css: {
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "space-between"
  }
}, __cssprop("div", {
  css: {
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "fontWeight": "500",
    "--tw-text-opacity": "1",
    "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
    "overflow": "hidden",
    "textOverflow": "ellipsis",
    "whiteSpace": "nowrap"
  }
}, title), __cssprop("div", {
  css: {
    "marginLeft": "0.5rem",
    "flexShrink": "0",
    "display": "flex"
  }
}, __cssprop("span", {
  css: {
    "paddingLeft": "0.5rem",
    "paddingRight": "0.5rem",
    "display": "inline-flex",
    "fontSize": "0.75rem",
    "lineHeight": "1.25rem",
    "fontWeight": "600",
    "borderRadius": "9999px",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(209, 250, 229, var(--tw-bg-opacity))",
    "--tw-text-opacity": "1",
    "color": "rgba(6, 95, 70, var(--tw-text-opacity))",
    ".dark &": {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(6, 95, 70, var(--tw-bg-opacity))",
      "--tw-text-opacity": "1",
      "color": "rgba(209, 250, 229, var(--tw-text-opacity))"
    }
  }
}, pill))), children || __cssprop("div", {
  css: {
    "marginTop": "0.5rem",
    "display": "flex",
    "justifyContent": "space-between"
  }
}, __cssprop("div", {
  css: {
    "@media (min-width: 640px)": {
      "display": "flex"
    }
  }
}, __cssprop("div", {
  css: {
    "display": "flex",
    "alignItems": "center",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "--tw-text-opacity": "1",
    "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
    ".dark &": {
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    }
  }
}, __cssprop("svg", {
  css: {
    "flexShrink": "0",
    "marginRight": "0.375rem",
    "height": "1.25rem",
    "width": "1.25rem",
    "--tw-text-opacity": "1",
    "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
    ".dark &": {
      "--tw-text-opacity": "1",
      "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
    }
  },
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  fill: "currentColor",
  "aria-hidden": "true"
}, titleIcon), titleText)), __cssprop("div", {
  css: {
    "marginLeft": "0.5rem",
    "display": "flex",
    "alignItems": "center",
    "fontSize": "0.875rem",
    "lineHeight": "1.25rem",
    "--tw-text-opacity": "1",
    "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
    ".dark &": {
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    }
  }
}, __cssprop("svg", {
  css: {
    "flexShrink": "0",
    "marginRight": "0.375rem",
    "height": "1.25rem",
    "width": "1.25rem",
    "--tw-text-opacity": "1",
    "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
    ".dark &": {
      "--tw-text-opacity": "1",
      "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
    }
  },
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 20 20",
  fill: "currentColor",
  "aria-hidden": "true"
}, pillIcon), pillText)))));

const AccountList = () => {
  const dealers = useAuth(state => state.dealers);
  const user = useAuth(state => state.user);
  const getDealers = useAuth(state => state.getDealers);
  const isDealer = dealers !== null && Array.isArray(dealers) && dealers.length >= 1 && dealers[0].name;
  const noDealers = dealers === null;
  const hasDealers = user && Array.isArray(user.customData.dealers) && user.customData.dealers.length >= 1;
  const loading = hasDealers && noDealers;
  useEffect(() => {
    if (!loading) {
      console.log("already loaded");
      return;
    }

    const timeout = setTimeout(async () => {
      console.log("refetching dealers");

      if (hasDealers && isDealer) {
        console.log("already loaded", isDealer);
        return undefined;
      }

      return await getDealers(user);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);
  return __cssprop("ul", {
    id: "accounts",
    css: {
      "marginLeft": "1rem",
      "marginRight": "1rem"
    }
  }, loading ? __cssprop(AccountLoading, null) : dealers && dealers.map(dealer => __cssprop(AccountItem, {
    key: dealer.name,
    dealer: dealer
  })));
};

const AccountLoading = () => {
  return __cssprop(Item, null, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))"
      },
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "1.25rem",
      "paddingBottom": "1.25rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "animation": "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
      "marginLeft": "-1rem",
      "marginTop": "-1rem",
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "center",
      "flexWrap": "wrap",
      "@media (min-width: 640px)": {
        "flexWrap": "nowrap"
      }
    }
  }, __cssprop("div", {
    css: {
      "marginLeft": "1rem",
      "marginTop": "1rem"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(156, 163, 175, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))"
      },
      "height": "3rem",
      "width": "3rem",
      "overflow": "hidden",
      "borderRadius": "9999px",
      "flexShrink": "0"
    }
  }), __cssprop("div", {
    css: {
      "marginLeft": "1rem"
    }
  }, __cssprop("h3", {
    css: {
      "display": "flex",
      "height": "1.25rem",
      "marginBottom": "0.25rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(156, 163, 175, var(--tw-bg-opacity))",
      "borderRadius": "0.25rem",
      "width": "13rem"
    }
  }), __cssprop("p", {
    css: {
      "display": "flex",
      "height": "1rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(156, 163, 175, var(--tw-bg-opacity))",
      "borderRadius": "0.25rem",
      "width": "8rem"
    }
  })))), __cssprop("div", {
    css: {
      "marginLeft": "1rem",
      "marginTop": "1rem",
      "flexShrink": "0",
      "display": "flex"
    }
  }, __cssprop("div", {
    css: {
      "position": "relative",
      "display": "inline-flex",
      "alignItems": "center",
      "borderRadius": "9999px",
      "padding": "0.5rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    }
  }, __cssprop("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    css: {
      "height": "1.25rem",
      "width": "1.25rem"
    },
    fill: "none",
    viewBox: "0 0 24 24",
    stroke: "currentColor"
  }, __cssprop("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 2,
    d: "M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
  })))))));
};

const AccountItem = ({
  dealer
}) => {
  const social = dealer && dealer.social;
  const address = dealer && dealer.address;
  return __cssprop(Item, {
    id: dealer.name.split(" ").join("-").toLowerCase()
  }, __cssprop("div", {
    css: {
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "1.25rem",
      "paddingBottom": "1.25rem",
      "borderBottomWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      ".dark &": {
        "--tw-border-opacity": "1",
        "borderColor": "rgba(55, 65, 81, var(--tw-border-opacity))"
      },
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "marginLeft": "-1rem",
      "marginTop": "-1rem",
      "display": "flex",
      "justifyContent": "space-between",
      "alignItems": "center",
      "flexWrap": "wrap",
      "@media (min-width: 640px)": {
        "flexWrap": "nowrap"
      }
    }
  }, __cssprop("div", {
    css: {
      "marginLeft": "1rem",
      "marginTop": "1rem"
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center"
    }
  }, __cssprop("div", {
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))"
      },
      "height": "3rem",
      "width": "3rem",
      "overflow": "hidden",
      "borderRadius": "9999px",
      "display": "flex",
      "justifyContent": "center"
    }
  }, dealer.logo ? __cssprop("img", {
    css: {
      "height": "3rem",
      "width": "3rem",
      "borderRadius": "9999px"
    },
    src: dealer.logo,
    alt: dealer.name
  }) : __cssprop("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    css: {
      "height": "3.5rem",
      "width": "3.5rem",
      "paddingTop": "0.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(31, 41, 55, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(243, 244, 246, var(--tw-text-opacity))"
      }
    },
    viewBox: "0 0 20 20",
    fill: "currentColor"
  }, __cssprop("path", {
    fillRule: "evenodd",
    d: "M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z",
    clipRule: "evenodd"
  }))), __cssprop("div", {
    css: {
      "marginLeft": "1rem"
    }
  }, __cssprop("h3", {
    css: {
      "fontSize": "1.125rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
      }
    }
  }, dealer.name), __cssprop("p", {
    css: {
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    }
  }, dealer.priorityId)))), __cssprop("div", {
    css: {
      "marginLeft": "1rem",
      "marginTop": "1rem",
      "flexShrink": "0",
      "display": "flex"
    }
  }, __cssprop("button", {
    type: "button",
    css: {
      "position": "relative",
      "display": "inline-flex",
      "alignItems": "center",
      "borderRadius": "9999px",
      "padding": "0.5rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(229, 231, 235, var(--tw-text-opacity))",
        ":focus": {
          "--tw-ring-offset-color": "#111827"
        }
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-ring-offset-color": "#f3f4f6"
      }
    }
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Edit account"), __cssprop("svg", {
    "x-heroicon": "solid/dots-vertical",
    xmlns: "http://www.w3.org/2000/svg",
    css: {
      "height": "1.25rem",
      "width": "1.25rem"
    },
    fill: "none",
    viewBox: "0 0 24 24",
    stroke: "currentColor"
  }, __cssprop("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 2,
    d: "M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
  })))))), __cssprop("ul", {
    className: "Dealer-details",
    css: {
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-divide-y-reverse": 0,
        "borderTopWidth": "calc(1px * calc(1 - var(--tw-divide-y-reverse)))",
        "borderBottomWidth": "calc(1px * var(--tw-divide-y-reverse))",
        "--tw-divide-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-divide-opacity))"
      },
      ".dark &": {
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-divide-opacity": "1",
          "borderColor": "rgba(55, 65, 81, var(--tw-divide-opacity))"
        }
      }
    }
  }, __cssprop(DetailItem, {
    css: !dealer.phone && {
      "opacity": "0.25"
    },
    title: "General Contact",
    pill: "Phone",
    titleIcon: __cssprop("path", {
      d: "M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
    }),
    titleText: dealer.name,
    pillIcon: __cssprop("path", {
      d: "M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
    }),
    pillText: /^[61]{2}/.test(dealer.phone) ? `(+61) 0${dealer.phone.split("61")[1]}` : dealer.phone
  }), __cssprop(DetailItem, {
    css: !dealer.sms && {
      "opacity": "0.25"
    },
    title: "Marketing Contact",
    pill: "SMS/Mobile",
    titleIcon: __cssprop("path", {
      fillRule: "evenodd",
      d: "M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z",
      clipRule: "evenodd"
    }),
    titleText: dealer.contactName || dealer.name,
    pillIcon: __cssprop("path", {
      d: "M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"
    }),
    pillText: dealer.sms ? `(+61) 0${dealer.sms.split("61")[1]}` : /^[61]{2}/.test(dealer.phone) ? `(+61) 0${dealer.phone.split("61")[1]}` : dealer.phone
  }), __cssprop(DetailItem, {
    css: !dealer.email[0] && {
      "opacity": "0.25"
    },
    title: "eCommerce Contacts",
    pill: "eMail/Address"
  }, dealer.email.map((email, index) => __cssprop("div", {
    key: email,
    css: {
      "marginTop": "0.5rem",
      "display": "flex",
      "justifyContent": "space-between"
    }
  }, __cssprop("div", {
    css: {
      "@media (min-width: 640px)": {
        "display": "flex"
      }
    }
  }, index === 0 ? __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    }
  }, __cssprop("svg", {
    css: {
      "flexShrink": "0",
      "marginRight": "0.375rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      }
    },
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    "aria-hidden": "true"
  }, __cssprop("path", {
    d: "M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
  })), dealer.name) : __cssprop("div", {
    css: {
      "display": "flex"
    }
  })), __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    }
  }, __cssprop("svg", {
    css: {
      "flexShrink": "0",
      "marginRight": "0.375rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      }
    },
    "data-description": "Heroicon name: solid/mail",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    "aria-hidden": "true"
  }, __cssprop("path", {
    d: "M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
  }), __cssprop("path", {
    d: "M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
  })), email)))), social && __cssprop(DetailItem, {
    css: !dealer.email[0] && {
      "opacity": "0.25"
    },
    title: "eCommerce Contacts",
    pill: social && Object.keys(social).map(key => {
      const website = social["website"];

      switch (key) {
        case "website":
          if (Array.isArray(website) && website.length > 0 && website[0]) return "WEBSITE";
          break;

        case "minisite":
          if (social[key]) return "MINISITE";
          break;

        case "placeId":
          if (social[key]) return "GOOGLE";
          break;

        case "fbId":
          if (social[key]) return "FACEBOOK";
          break;

        case "igId":
          if (social[key]) return "INSTAGRAM";
          break;

        default:
          break;
      }
    }).filter(x => x).toString()
  }, __cssprop("div", {
    css: {
      "marginTop": "0.5rem",
      "display": "flex",
      "flexWrap": "wrap",
      "justifyContent": "space-between"
    }
  }, social.website && social.website[0] && __cssprop(React.Fragment, null, __cssprop("div", {
    css: {
      "@media (min-width: 640px)": {
        "display": "flex"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    }
  }, __cssprop("svg", {
    css: {
      "flexShrink": "0",
      "marginRight": "0.375rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      }
    },
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor"
  }, __cssprop("path", {
    fillRule: "evenodd",
    d: "M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z",
    clipRule: "evenodd"
  })), social.website.toString()))), social.minisite && __cssprop(React.Fragment, null, __cssprop("div", {
    css: {
      "@media (min-width: 640px)": {
        "display": "flex"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    }
  }, __cssprop("svg", {
    css: {
      "flexShrink": "0",
      "marginRight": "0.375rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      }
    },
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor"
  }, __cssprop("path", {
    fillRule: "evenodd",
    d: "M4.083 9h1.946c.089-1.546.383-2.97.837-4.118A6.004 6.004 0 004.083 9zM10 2a8 8 0 100 16 8 8 0 000-16zm0 2c-.076 0-.232.032-.465.262-.238.234-.497.623-.737 1.182-.389.907-.673 2.142-.766 3.556h3.936c-.093-1.414-.377-2.649-.766-3.556-.24-.56-.5-.948-.737-1.182C10.232 4.032 10.076 4 10 4zm3.971 5c-.089-1.546-.383-2.97-.837-4.118A6.004 6.004 0 0115.917 9h-1.946zm-2.003 2H8.032c.093 1.414.377 2.649.766 3.556.24.56.5.948.737 1.182.233.23.389.262.465.262.076 0 .232-.032.465-.262.238-.234.498-.623.737-1.182.389-.907.673-2.142.766-3.556zm1.166 4.118c.454-1.147.748-2.572.837-4.118h1.946a6.004 6.004 0 01-2.783 4.118zm-6.268 0C6.412 13.97 6.118 12.546 6.03 11H4.083a6.004 6.004 0 002.783 4.118z",
    clipRule: "evenodd"
  })), `https://mydolphin.com/pool-cleaners-${social.minisite}`))))), social && address && __cssprop(DetailItem, {
    css: !dealer.sms && {
      "opacity": "0.25"
    },
    title: "Marketing Contact",
    pill: __cssprop("span", {
      css: {
        "textTransform": "capitalize"
      }
    }, `${social.minisite ? social.minisite.split("/")[0] : address.city}, ${address.state || ""}`),
    titleIcon: __cssprop("path", {
      fillRule: "evenodd",
      d: "M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z",
      clipRule: "evenodd"
    }),
    titleText: address.fullAddress || "",
    pillIcon: __cssprop("path", {
      fillRule: "evenodd",
      d: "M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z",
      clipRule: "evenodd"
    }),
    pillText: `${address.line2 ? address.line1 + " " + address.line2 : address.line1},
					${address.city} ${address.state} ${address.postalCode} ${address.country}`
  })));
};

export default AccountList;