import _isEmpty from "../../_snowpack/pkg/ramda/es/isEmpty.js";
import create from "../../_snowpack/pkg/zustand.js";
import { devtools } from "../../_snowpack/pkg/zustand/middleware.js";
import * as Realm from "../../_snowpack/pkg/realm-web.js";
import * as t from "../../_snowpack/pkg/io-ts.js";
import { date, fromRefinement } from "../../_snowpack/pkg/io-ts-types.js";
/** Realm */

const REALM_APP_ID = "mtau-web-app-esnki";
const REALM_DB_ID = "MTAU-DB";
export const app = new Realm.App({
  id: REALM_APP_ID
});
export const mongodb = app.currentUser && app.currentUser.mongoClient(REALM_DB_ID);
export const ObjectId = id => new Realm.BSON.ObjectID(id);
/** Refine Types */

const isUser = u => u instanceof Realm.User;

export const user = fromRefinement("User", isUser);

const isBuffer = u => u instanceof Buffer;

export const buffer = fromRefinement("Buffer", isBuffer);

const isObjectID = u => u instanceof Realm.BSON.ObjectID;

export const objectid = fromRefinement("ObjectID", isObjectID);
/** Typings */

const oauth = t.keyof({
  facebook: null,
  google: null
});
const id = t.union([t.string, t.number, buffer, objectid]);
const address = t.partial({
  fullAddress: t.string,
  line1: t.string,
  line2: t.string,
  city: t.string,
  state: t.string,
  postalCode: t.string,
  country: t.string
});
const social = t.partial({
  website: t.array(t.string),
  minisite: t.string,
  placeId: t.string,
  fbId: t.string,
  igId: t.string
});
const ltyp = t.partial({
  type: t.string
});
const lord = t.type({
  coordinates: t.array(t.number)
});
const location = t.intersection([ltyp, lord]);
const meta = t.partial({
  distance: t.number,
  createdAt: date,
  updatedAt: date
});
const DealerRequired = t.type({
  _id: id,
  name: t.string,
  email: t.array(t.string),
  phone: t.string
});
const DealerOptional = t.partial({
  priorityId: t.number,
  affiliateId: t.number,
  contactName: t.string,
  address: address,
  social: social,
  logo: t.string,
  location: location,
  meta: meta,
  flag: t.array(t.string),
  type: t.array(t.string),
  status: t.string,
  franchise: t.union([t.array(t.string), t.string]),
  sms: t.string,
  saleshtmlForceId: t.string,
  rep: id,
  _v: t.union([t.string, t.number])
});
const dealer = t.intersection([DealerRequired, DealerOptional]);
export const profile = t.partial({
  /** Full user name */
  name: t.string,
  email: t.string,

  /** Avatar/profile image */
  pictureUrl: t.string,
  firstName: t.string,
  lastName: t.string,
  gender: t.string,
  birthday: t.string,
  minAge: t.string,
  maxAge: t.string,

  /** Accounts this user manages */
  dealers: t.array(id)
});

/**
 * Global Auth State Store
 *
 * Now controling: User, Profile and Dealers
 *
 * @param {AuthState} state of the Auth: Realm: User
 * @returns {UseStore<AuthState>} useAuth hook
 * @see ZUSTAND 🐻 https://github.com/pmndrs/zustand
 * @see Realm 🌄 https://realm.io/
 */
const useAuth = create(devtools((set, get) => ({
  isAuthenticated: Boolean(JSON.parse(localStorage.getItem("isAuthenticated") || "false")),
  hasRequestedReset: JSON.parse(localStorage.getItem("hasRequestedReset") || "false"),
  user: app.currentUser,
  profile: app.currentUser && !_isEmpty(app.currentUser.customData) ? app.currentUser.customData : null,
  dealers: JSON.parse(localStorage.getItem("dealers") || "null"),
  login: async (email, password, redirectUri, oauth) => {
    const currentUser = get().user;
    const credentials = redirectUri ? oauth === "facebook" ? Realm.Credentials.facebook(redirectUri) : Realm.Credentials.google(redirectUri) : Realm.Credentials.emailPassword(email, password);

    try {
      if (currentUser !== null) throw new Error("already loggedin");
      const user = await app.logIn(credentials);
      const currentUserID = app && app.currentUser && app.currentUser.id;
      const userID = user.id;
      if (currentUserID !== userID) throw new Error("currentUser does not match user.id");
      get().getDealers(user);
      localStorage.setItem("isAuthenticated", JSON.stringify(true));

      if (!_isEmpty(user.customData)) {
        set({
          isAuthenticated: true,
          user: user,
          profile: user.customData
        });
        return user;
      }

      set({
        isAuthenticated: true,
        user: user
      });
      return user;
    } catch (err) {
      console.warn("Failed to login", err);
      return undefined;
    }
  },
  logout: async () => {
    const currentUser = get().user;

    try {
      if (currentUser === null) throw new Error("Already logged out");
      currentUser.logOut();
      localStorage.setItem("isAuthenticated", "false");
      localStorage.setItem("hasRequestedReset", "false");
      localStorage.setItem("dealers", "null");
      return set({
        isAuthenticated: false,
        hasRequestedReset: false,
        user: null,
        profile: null,
        dealers: null
      });
    } catch (err) {
      console.warn("Failed to logout", err);
      return undefined;
    }
  },
  signup: async (email, password) => {
    try {
      return await app.emailPasswordAuth.registerUser(email, password);
    } catch (err) {
      console.warn("Failed to signup", err);
    }
  },
  requestResetPassword: async email => {
    try {
      await app.emailPasswordAuth.sendResetPasswordEmail(email);
      return set(state => {
        localStorage.setItem("hasRequestedReset", JSON.stringify(email));
        return {
          hasRequestedReset: email
        };
      });
    } catch (err) {
      console.warn("Failed to request reset password", err);
      return undefined;
    }
  },
  resetPassword: async (password, token, tokenId) => {
    try {
      await app.emailPasswordAuth.resetPassword(token, tokenId, password);
      return set(state => {
        localStorage.setItem("hasRequestedReset", JSON.stringify(false));
        return {
          hasRequestedReset: false
        };
      });
    } catch (err) {
      console.warn("Failed to reset password", err);
      return undefined;
    }
  },
  getDealers: async user => {
    try {
      if (user === null) {
        user === null && console.warn("no user", user);
        return;
      }

      if (!(user.customData && user.customData.dealers)) throw new Error("User has no dealers");
      const data = await user.mongoClient(REALM_DB_ID).db("customer").collection("dealers").find({
        $or: user.customData.dealers.map(_id => ({
          _id
        }))
      });
      if (data !== null && !data[0]._id) throw new Error(data ? data.toString() : "No results");
      localStorage.setItem("dealers", JSON.stringify(data));
      return set({
        dealers: data
      });
    } catch (err) {
      console.warn("Failed to get dealers", err);
      return undefined;
    }
  },
  updateProfile: async profile => {
    try {
      const user = get().user;

      if (user === null || profile === null) {
        return undefined;
      }

      await user.mongoClient(REALM_DB_ID).db("portal").collection("users").updateOne({
        userID: user.id
      }, {
        $set: profile
      }, {
        upsert: true
      });
      const data = await user.refreshCustomData();
      if (_isEmpty(data)) throw new Error(data ? data.toString() : "No results");
      return set({
        profile: data
      });
    } catch (err) {
      console.warn("Failed to get profile", err);
      return undefined;
    }
  }
})));
export default useAuth;