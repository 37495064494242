import { css as _css } from "../../../_snowpack/pkg/@emotion/react.js";
import React from '../../../_snowpack/pkg/react.js';
import ToggleDark from '../UI/ToggleDark.js'; // import GridLines from '../../images/GridLines'

import GridLinesV2 from '../../images/GridLinesV2.js';
/** Minumum length 8, at least 1 uppercase, 1 lowercase, 1 number & 1 special character */

import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";
export const passwordPattern = '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'; // for use with `pattern=""`

export const passwordRegExp = new RegExp(passwordPattern); // for use with validation

/** Styles */

export const InputStyle = {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(249, 250, 251, var(--tw-bg-opacity))",
  ".dark &": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))",
    "--tw-text-opacity": "1",
    "color": "rgba(31, 41, 55, var(--tw-text-opacity))",
    "::placeholder": {
      "--tw-placeholder-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-placeholder-opacity))"
    },
    "--tw-border-opacity": "1",
    "borderColor": "rgba(156, 163, 175, var(--tw-border-opacity))",
    ":hover": {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
    },
    ":focus": {
      "--tw-border-opacity": "1",
      "borderColor": "rgba(55, 65, 81, var(--tw-border-opacity))"
    }
  },
  "--tw-text-opacity": "1",
  "color": "rgba(75, 85, 99, var(--tw-text-opacity))",
  "::placeholder": {
    "--tw-placeholder-opacity": "1",
    "color": "rgba(107, 114, 128, var(--tw-placeholder-opacity))"
  },
  "letterSpacing": "0.1em",
  "fontFamily": "Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "marginBottom": "0.75rem",
  "width": "100%",
  "maxWidth": "100%",
  "borderRadius": "0.25rem",
  "borderWidth": "2px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
    "--tw-text-opacity": "1",
    "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
  },
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
  }
};
export const LabelStyle = {
  "fontFamily": "Magistral, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
  "letterSpacing": "0.1em",
  "marginBottom": "0.25rem",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem"
};
export const ButtonStyle = {
  "fontFamily": "Flexo, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "marginTop": "1rem",
  "marginBottom": "1rem",
  "width": "100%",
  "maxWidth": "100%",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(107, 114, 128, var(--tw-bg-opacity))",
  ".dark &": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(55, 65, 81, var(--tw-bg-opacity))",
    "--tw-text-opacity": "1",
    "color": "rgba(243, 244, 246, var(--tw-text-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(156, 163, 175, var(--tw-border-opacity))",
    ":hover": {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))",
      "--tw-text-opacity": "1",
      "color": "rgba(249, 250, 251, var(--tw-text-opacity))"
    },
    ":focus": {
      "--tw-border-opacity": "1",
      "borderColor": "rgba(31, 41, 55, var(--tw-border-opacity))"
    }
  },
  "--tw-text-opacity": "1",
  "color": "rgba(249, 250, 251, var(--tw-text-opacity))",
  "borderRadius": "0.25rem",
  "borderWidth": "2px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
  ":hover": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(156, 163, 175, var(--tw-bg-opacity))",
    "--tw-text-opacity": "1",
    "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
  },
  ":focus": {
    "outline": "2px solid transparent",
    "outlineOffset": "2px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
    "--tw-ring-opacity": "1",
    "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
  }
};
export const LinkStyle = {
  "fontSize": "0.75rem",
  "lineHeight": "1rem",
  "--tw-text-opacity": "1",
  "color": "rgba(75, 85, 99, var(--tw-text-opacity))",
  ".dark &": {
    "--tw-text-opacity": "1",
    "color": "rgba(209, 213, 219, var(--tw-text-opacity))",
    ":hover": {
      "--tw-text-opacity": "1",
      "color": "rgba(243, 244, 246, var(--tw-text-opacity))"
    }
  },
  ":hover": {
    "textDecoration": "underline"
  }
};
export const FormStyle = _css`
	> *[hidden='true'] {
		${{
  "visibility": "hidden"
}}
	}
	${{
  "display": "flex",
  "flexDirection": "column",
  "width": "20rem",
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginBottom": "3rem",
  "padding": "1.5rem",
  "fontSize": "1rem",
  "lineHeight": "1.5rem",
  "--tw-text-opacity": "1",
  "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
  "--tw-shadow": "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
  "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
  "borderRadius": "0.25rem",
  "borderTopWidth": "2px",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(249, 250, 251, var(--tw-border-opacity))",
  "backgroundImage": "linear-gradient(to bottom, var(--tw-gradient-stops))",
  "--tw-gradient-from": "#f3f4f6",
  "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to, rgba(243, 244, 246, 0))",
  "--tw-gradient-to": "#e5e7eb",
  ".dark &": {
    "--tw-text-opacity": "1",
    "color": "rgba(209, 213, 219, var(--tw-text-opacity))",
    "--tw-border-opacity": "1",
    "borderColor": "rgba(107, 114, 128, var(--tw-border-opacity))",
    "--tw-gradient-from": "#374151",
    "--tw-gradient-stops": "var(--tw-gradient-from), var(--tw-gradient-to, rgba(55, 65, 81, 0))",
    "--tw-gradient-to": "#1f2937"
  }
}}
`;
/**
 * A wrapper for authentication forms
 * @public
 */

export const AuthWrapper = props => {
  const pos = [99, 66, 33, 11];
  return __cssprop("div", {
    css: {
      "position": "relative",
      "display": "flex",
      "width": "100%",
      "minHeight": "100vh",
      "justifyContent": "center",
      "alignItems": "center",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))"
      },
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
    }
  }, __cssprop("div", {
    css: {
      "position": "absolute",
      "zIndex": "20",
      "top": "0px",
      "right": "0px",
      "padding": "1rem"
    }
  }, __cssprop(ToggleDark, null)), __cssprop("div", {
    css: {
      "zIndex": "10",
      "display": "flex",
      "flexDirection": "column",
      "alignItems": "center"
    }
  }, __cssprop("h1", {
    css: {
      "display": "inline-flex",
      "fontFamily": "Flexo, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
      "fontWeight": "500",
      "letterSpacing": "0.1em",
      "fontSize": "2.25rem",
      "lineHeight": "2.5rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      },
      "marginBottom": "2.5rem"
    },
    "aria-label": "Marketing Portal"
  }, "Marketing", __cssprop("span", null, __cssprop("span", {
    css: {
      "letterSpacing": "-0.025em"
    }
  }, "\xA0"), "P", __cssprop("span", {
    css: {
      "display": "inline-flex",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))"
    }
  }, "o"), "rtal")), props.children), __cssprop(GridLinesV2, {
    css: {
      "position": "absolute",
      "zIndex": "0",
      "width": "100%",
      "height": "100%",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))"
    }
  }));
};