function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from '../../_snowpack/pkg/react.js';
import { jsx as __cssprop } from "../../_snowpack/pkg/@emotion/react.js";

const GridLinesV2 = props => {
  return __cssprop("svg", _extends({
    version: "1.2",
    baseProfile: "tiny",
    id: "grid-lines",
    xmlns: "http://www.w3.org/2000/svg",
    x: "0",
    y: "0",
    viewBox: "0 0 400 378",
    overflow: "visible",
    fill: "none"
  }, props), __cssprop("defs", null, __cssprop("linearGradient", {
    id: "line-gradient--1",
    gradientUnits: "userSpaceOnUse",
    gradientTransform: "rotate(1 200 189)",
    x1: "399.75",
    y1: "189",
    x2: "0.25",
    y2: "189"
  }, __cssprop("animateTransform", {
    values: "5 200 189;-60 200 189;-120 200 189;-181 200 189",
    attributeType: "XML",
    attributeName: "gradientTransform",
    type: "rotate",
    repeatCount: "indefinite",
    dur: "12s",
    additive: "sum"
  }), __cssprop("stop", {
    offset: "0.47",
    stopColor: "currentColor",
    stopOpacity: "0.075"
  }, __cssprop("animate", {
    values: "0.075;0.100;0.125;0.150;0.125;0.100;0.075;",
    attributeName: "stop-opacity",
    dur: "6s",
    repeatCount: "indefinite"
  })), __cssprop("stop", {
    offset: "0.50",
    stopColor: "currentColor",
    stopOpacity: "1"
  }, __cssprop("animate", {
    values: "1;0.975;0.950;0.925;0.950;0.975;1;",
    attributeName: "stop-opacity",
    dur: "6s",
    repeatCount: "indefinite"
  })), __cssprop("stop", {
    offset: "0.53",
    stopColor: "currentColor",
    stopOpacity: "0.075"
  }, __cssprop("animate", {
    values: "0.075;0.100;0.125;0.150;0.125;0.100;0.075;",
    attributeName: "stop-opacity",
    dur: "6s",
    repeatCount: "indefinite"
  }))), __cssprop("linearGradient", {
    id: "line-gradient-0",
    gradientUnits: "userSpaceOnUse",
    gradientTransform: "rotate(0 200 189)",
    x1: "399.75",
    y1: "189",
    x2: "0.25",
    y2: "189"
  }, __cssprop("animateTransform", {
    values: "0 200 189;-60 200 189;-120 200 189;-180 200 189",
    attributeType: "XML",
    attributeName: "gradientTransform",
    type: "rotate",
    repeatCount: "indefinite",
    dur: "12s",
    additive: "sum"
  }), __cssprop("stop", {
    offset: "0.47",
    stopColor: "currentColor",
    stopOpacity: "0.075"
  }, __cssprop("animate", {
    values: "0.075;0.100;0.125;0.150;0.125;0.100;0.075;",
    attributeName: "stop-opacity",
    dur: "6s",
    repeatCount: "indefinite"
  })), __cssprop("stop", {
    offset: "0.50",
    stopColor: "currentColor",
    stopOpacity: "1"
  }, __cssprop("animate", {
    values: "1;0.975;0.950;0.925;0.950;0.975;1;",
    attributeName: "stop-opacity",
    dur: "6s",
    repeatCount: "indefinite"
  })), __cssprop("stop", {
    offset: "0.53",
    stopColor: "currentColor",
    stopOpacity: "0.075"
  }, __cssprop("animate", {
    values: "0.075;0.100;0.125;0.150;0.125;0.100;0.075;",
    attributeName: "stop-opacity",
    dur: "6s",
    repeatCount: "indefinite"
  }))), __cssprop("linearGradient", {
    id: "line-gradient-1",
    gradientUnits: "userSpaceOnUse",
    gradientTransform: "rotate(-1 200 189)",
    x1: "399.75",
    y1: "189",
    x2: "0.25",
    y2: "189"
  }, __cssprop("animateTransform", {
    values: "-1 200 189;-60 200 189;-120 200 189;-179 200 189",
    attributeType: "XML",
    attributeName: "gradientTransform",
    type: "rotate",
    repeatCount: "indefinite",
    dur: "12s",
    additive: "sum"
  }), __cssprop("stop", {
    offset: "0.47",
    stopColor: "currentColor",
    stopOpacity: "0.075"
  }, __cssprop("animate", {
    values: "0.075;0.100;0.125;0.150;0.125;0.100;0.075;",
    attributeName: "stop-opacity",
    dur: "6s",
    repeatCount: "indefinite"
  })), __cssprop("stop", {
    offset: "0.50",
    stopColor: "currentColor",
    stopOpacity: "1"
  }, __cssprop("animate", {
    values: "1;0.975;0.950;0.925;0.950;0.975;1;",
    attributeName: "stop-opacity",
    dur: "6s",
    repeatCount: "indefinite"
  })), __cssprop("stop", {
    offset: "0.53",
    stopColor: "currentColor",
    stopOpacity: "0.075"
  }, __cssprop("animate", {
    values: "0.075;0.100;0.125;0.150;0.125;0.100;0.075;",
    attributeName: "stop-opacity",
    dur: "6s",
    repeatCount: "indefinite"
  })))), __cssprop("g", {
    id: "hex-lines",
    stroke: "url(#line-gradient-0)",
    transform: "translate(-343 -80) scale(1.5 1.5)"
  }, __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    strokeWidth: "0.50",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(5 0)",
    strokeWidth: "0.49",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(10 0)",
    strokeWidth: "0.48",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(15 0)",
    strokeWidth: "0.47",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(20 0)",
    strokeWidth: "0.46",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(25 0)",
    strokeWidth: "0.45",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(30 0)",
    strokeWidth: "0.44",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(35 0)",
    strokeWidth: "0.43",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(40 0)",
    strokeWidth: "0.42",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(45 0)",
    strokeWidth: "0.41",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(50 0)",
    strokeWidth: "0.40",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(55)",
    strokeWidth: "0.39",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(60 0)",
    strokeWidth: "0.38",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(65 0)",
    strokeWidth: "0.37",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(70 0)",
    strokeWidth: "0.36",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(75 0)",
    strokeWidth: "0.35",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(80 0)",
    strokeWidth: "0.34",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(85 0)",
    strokeWidth: "0.33",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(90 0)",
    strokeWidth: "0.32",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(95 0)",
    strokeWidth: "0.31",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(100 0)",
    strokeWidth: "0.30",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(105 0)",
    strokeWidth: "0.29",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(110 0)",
    strokeWidth: "0.28",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(115 0)",
    strokeWidth: "0.27",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(120 0)",
    strokeWidth: "0.26",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  })), __cssprop("g", {
    id: "hex-lines-mirrored",
    stroke: "url(#line-gradient-0)",
    transform: "translate(300 -80) scale(1.5 1.5)"
  }, __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    strokeWidth: "0.50",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(5 0)",
    strokeWidth: "0.49",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(10 0)",
    strokeWidth: "0.48",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(15 0)",
    strokeWidth: "0.47",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(20 0)",
    strokeWidth: "0.46",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(25 0)",
    strokeWidth: "0.45",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(30 0)",
    strokeWidth: "0.44",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(35 0)",
    strokeWidth: "0.43",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(40 0)",
    strokeWidth: "0.42",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(45 0)",
    strokeWidth: "0.41",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(50 0)",
    strokeWidth: "0.40",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(55)",
    strokeWidth: "0.39",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(60 0)",
    strokeWidth: "0.38",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(65 0)",
    strokeWidth: "0.37",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(70 0)",
    strokeWidth: "0.36",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(75 0)",
    strokeWidth: "0.35",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(80 0)",
    strokeWidth: "0.34",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient-1)",
    transform: "translate(85 0)",
    strokeWidth: "0.33",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(90 0)",
    strokeWidth: "0.32",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(95 0)",
    strokeWidth: "0.31",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(100 0)",
    strokeWidth: "0.30",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(105 0)",
    strokeWidth: "0.29",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    transform: "translate(110 0)",
    strokeWidth: "0.28",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(115 0)",
    strokeWidth: "0.27",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  }), __cssprop("path", {
    stroke: "url(#line-gradient--1)",
    transform: "translate(120 0)",
    strokeWidth: "0.26",
    d: "M135.62,377.5h128.76c25.11,0,48.9-14.22,61.46-36.66l64.35-115.18c12.42-22.84,12.42-50.48,0-73.32L325.84,37.16 C313.22,14.72,289.42,0.5,264.38,0.5H135.62c-25.11,0-48.9,14.22-61.46,36.66L9.82,152.34c-12.42,22.84-12.42,50.48,0,73.32 l64.35,115.18C86.72,363.35,110.51,377.5,135.62,377.5z"
  })));
};

export default GridLinesV2;