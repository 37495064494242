import React from "../../../_snowpack/pkg/react.js";
import { useHistory } from "../../../_snowpack/pkg/react-router-dom.js";
import { Menu, Transition } from "../../../_snowpack/pkg/@headlessui/react.js";
import useAuth from "../../hooks/AuthHooks.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const UserMenu = () => {
  const history = useHistory();
  const logout = useAuth(state => state.logout);
  const profile = useAuth(state => state.profile);
  return __cssprop(Menu, {
    as: "div",
    css: {
      "display": "inline-block",
      "textAlign": "left"
    }
  }, ({
    open
  }) => __cssprop(React.Fragment, null, __cssprop(Menu.Button, {
    as: "button",
    css: {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))",
        ":focus": {
          "--tw-ring-offset-color": "#111827"
        }
      },
      "height": "2rem",
      "width": "2rem",
      "overflow": "hidden",
      "borderRadius": "9999px",
      "display": "flex",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-ring-offset-color": "#f3f4f6"
      }
    },
    id: "user-menu-1",
    "aria-haspopup": "true",
    title: "Toggle user menu " + (open ? "open" : "closed")
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Open user menu"), profile && profile.pictureUrl ? __cssprop("img", {
    css: {
      "height": "2rem",
      "width": "2rem",
      "borderRadius": "9999px"
    },
    src: profile.pictureUrl,
    alt: ""
  }) : __cssprop("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    css: {
      "height": "2.5rem",
      "width": "2.5rem",
      "--tw-text-opacity": "1",
      "color": "rgba(31, 41, 55, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(243, 244, 246, var(--tw-text-opacity))"
      }
    },
    viewBox: "0 0 20 20",
    fill: "currentColor"
  }, __cssprop("path", {
    fillRule: "evenodd",
    d: "M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z",
    clipRule: "evenodd"
  }))), __cssprop(Transition, {
    show: open,
    enter: "transition duration-100 ease-out",
    enterFrom: "transform scale-95 opacity-0",
    enterTo: "transform scale-100 opacity-100",
    leave: "transition duration-75 ease-out",
    leaveFrom: "transform scale-100 opacity-100",
    leaveTo: "transform scale-95 opacity-0",
    className: "transform duration-75 ease-in-out scale-95 opacity-0"
  }, __cssprop(Menu.Items, {
    as: "ul",
    css: {
      "transformOrigin": "top right",
      "position": "absolute",
      "zIndex": "30",
      "right": "0px",
      "paddingTop": "0.25rem",
      "paddingBottom": "0.25rem",
      "marginRight": "0.375rem",
      "marginTop": "1.5rem",
      "width": "14rem",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      ":focus": {
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-opacity": "0.05",
        "--tw-ring-color": "rgba(0, 0, 0, var(--tw-ring-opacity))",
        "outline": "2px solid transparent",
        "outlineOffset": "2px"
      }
    },
    role: "menu",
    "aria-orientation": "vertical",
    "aria-labelledby": "user-menu-1"
  }, __cssprop(Menu.Item, {
    as: "li",
    css: {
      "cursor": "pointer",
      "display": "block",
      "width": "100%",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      "textAlign": "left",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      },
      ":focus": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))",
        "outline": "2px solid transparent",
        "outlineOffset": "2px"
      }
    },
    role: "menuitem",
    onClick: () => history.push("/profile"),
    onKeyDown: () => history.push("/profile")
  }, "Your Profile"), __cssprop(Menu.Item, {
    as: "li",
    css: {
      "cursor": "pointer",
      "display": "block",
      "width": "100%",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      "textAlign": "left",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      },
      ":focus": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))",
        "outline": "2px solid transparent",
        "outlineOffset": "2px"
      }
    },
    role: "menuitem",
    onClick: () => {
      logout();
      history.push("/login");
    },
    onKeyDown: () => {
      logout();
      history.push("/login");
    }
  }, "Sign Out")))));
};

export default UserMenu;