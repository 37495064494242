import { T as Type, s as success, a as failure, i as identity } from './common/index-7298396e.js';

/**
 * @since 0.4.4
 */
/**
 * Returns a codec from a refinement
 *
 * @since 0.4.4
 */

function fromRefinement(name, is) {
  return new Type(name, is, function (u, c) {
    return is(u) ? success(u) : failure(u, c);
  }, identity);
}

var isDate = function (u) {
  return u instanceof Date;
};
/**
 * @since 0.5.0
 */


var date = fromRefinement('Date', isDate);

export { date, fromRefinement };
