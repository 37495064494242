import React from "../../../_snowpack/pkg/react.js";
import { css, cx } from "../../../_snowpack/pkg/@emotion/css.js";
import { Link } from "../../../_snowpack/pkg/react-router-dom.js";
import { Transition } from "../../../_snowpack/pkg/@headlessui/react.js";
import useAuth from "../../hooks/AuthHooks.js";
import useUI from "../../hooks/UIHooks.js";
import Logo from "../../images/Logo.js";
import ToggleDark from "./ToggleDark.js";
import MobileMenuButton from "./MobileMenuButton.js";
/**
 * Mobile friendly & accessible menu popover
 *
 * show/hide based on isMobile true/false state
 *
 * @returns {JSX.Element} MobileMenu component
 */

import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const MobileMenu = props => {
  const openButton = React.useRef(null);
  const closeButton = React.useRef(null);
  const searchInput = React.useRef(null);
  const logout = useAuth(state => state.logout);
  const profile = useAuth(state => state.profile);
  const isMobile = useUI(state => state.isMobile);
  const toggleMobile = useUI(state => state.toggleMobile);
  const closeMobile = useUI(state => state.closeMobile);

  const focusCloseButton = () => closeButton?.current?.focus();

  return __cssprop("div", props, __cssprop(ToggleDark, null), __cssprop(MobileMenuButton, {
    id: "mobile-menu-button",
    "aria-expanded": isMobile,
    "aria-controls": "mobile-menu-popup",
    onClick: () => {
      toggleMobile();
      focusCloseButton();
    },
    onKeyDown: () => {
      toggleMobile();
      focusCloseButton();
    },
    css: [{
      "marginRight": "-0.5rem"
    }, "padding: 5px;"],
    ref: openButton
  }), __cssprop(Transition, {
    show: isMobile,
    as: React.Fragment,
    enter: cx(css({
      "transitionProperty": "opacity",
      "transitionTimingFunction": "linear",
      "transitionDuration": "300ms"
    })),
    enterFrom: cx(css({
      "opacity": "0"
    })),
    enterTo: cx(css({
      "opacity": "1"
    })),
    leave: cx(css({
      "transitionProperty": "opacity",
      "transitionTimingFunction": "linear",
      "transitionDuration": "300ms"
    })),
    leaveFrom: cx(css({
      "opacity": "1"
    })),
    leaveTo: cx(css({
      "opacity": "0"
    }))
  }, __cssprop("div", {
    id: "mobile-menu-overlay",
    css: {
      "margin": "0px !important",
      "position": "fixed",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))"
      },
      "opacity": "0.75"
    }
  })), __cssprop(Transition, {
    show: isMobile,
    as: React.Fragment,
    enter: cx(css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
      "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
      "transitionDuration": "150ms",
      "@media (min-width: 640px)": {
        "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
        "transitionDuration": "300ms"
      }
    })),
    enterFrom: cx(css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1.1",
      "--tw-scale-y": "1.1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "0",
      "@media (min-width: 640px)": {
        "--tw-translate-x": "100%",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "opacity": "1"
      }
    })),
    enterTo: cx(css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "1",
      "@media (min-width: 640px)": {
        "--tw-translate-x": "0px",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "opacity": "1"
      }
    })),
    leave: cx(css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1)",
      "transitionDuration": "150ms",
      "@media (min-width: 640px)": {
        "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
        "transitionDuration": "300ms"
      }
    })),
    leaveFrom: cx(css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "1",
      "@media (min-width: 640px)": {
        "--tw-translate-x": "0px",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "opacity": "1"
      }
    })),
    leaveTo: cx(css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1.1",
      "--tw-scale-y": "1.1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "0",
      "@media (min-width: 640px)": {
        "--tw-translate-x": "100%",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "opacity": "1"
      }
    }))
  }, __cssprop("nav", {
    id: "mobile-menu-popup",
    role: "menu",
    css: {
      "margin": "0px !important",
      "position": "fixed",
      "zIndex": "40",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      "height": "100%",
      "width": "100%",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))"
      },
      "@media (min-width: 640px)": {
        "top": "0px",
        "bottom": "0px",
        "left": "auto",
        "right": "0px",
        "maxWidth": "24rem",
        "width": "100%",
        "--tw-shadow": "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
      }
    }
  }, __cssprop("div", {
    css: {
      "height": "4rem",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "space-between",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem"
      }
    }
  }, __cssprop(Link, {
    to: "#",
    css: {
      "paddingTop": "0.5rem"
    }
  }, __cssprop(Logo, {
    css: {
      "display": "block",
      "height": "2rem",
      "width": "auto",
      "--tw-text-opacity": "1",
      "color": "rgba(53, 62, 71, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
      }
    },
    role: "img"
  })), __cssprop("button", {
    type: "button",
    css: [{
      "marginRight": "-0.5rem",
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "padding": "0.5rem",
      "borderRadius": "0.375rem",
      "--tw-text-opacity": "1",
      "color": "rgba(31, 41, 55, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))",
        ":focus": {
          "--tw-ring-offset-color": "#111827"
        }
      },
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-ring-offset-color": "#f3f4f6"
      }
    }, `padding: 5px;`],
    "aria-expanded": isMobile,
    onClick: () => closeMobile(),
    onKeyDown: () => closeMobile(),
    ref: closeButton
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Open main menu"), __cssprop("svg", {
    "x-heroicon": "outline/x",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    css: {
      "display": "block",
      "height": "1.5rem",
      "width": "1.5rem"
    }
  }, __cssprop("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2",
    d: "M6 18L18 6M6 6l12 12"
  })))), __cssprop("div", {
    css: {
      "marginTop": "0.5rem",
      "maxWidth": "94rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "position": "relative",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ":focus-within": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))"
      }
    }
  }, __cssprop("label", {
    htmlFor: "search",
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Search all inboxes"), __cssprop("input", {
    id: "search",
    type: "search",
    placeholder: "search",
    css: {
      "display": "block",
      "width": "100%",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(209, 213, 219, var(--tw-border-opacity))",
      ".dark &": {
        "--tw-border-opacity": "1",
        "borderColor": "rgba(75, 85, 99, var(--tw-border-opacity))"
      },
      "backgroundColor": "transparent",
      "borderRadius": "0.375rem",
      "paddingLeft": "2.5rem",
      "::placeholder": {
        "--tw-placeholder-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-placeholder-opacity))"
      },
      ":focus": {
        "--tw-border-opacity": "1",
        "borderColor": "rgba(88, 200, 223, var(--tw-border-opacity))",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      }
    },
    ref: searchInput
  }), __cssprop("div", {
    css: {
      "position": "absolute",
      "top": "0px",
      "bottom": "0px",
      "left": "0px",
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "paddingLeft": "0.75rem"
    }
  }, __cssprop("svg", {
    "x-heroicon": "solid/search",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    "aria-hidden": "true",
    css: {
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(243, 244, 246, var(--tw-text-opacity))"
      }
    }
  }, __cssprop("path", {
    fillRule: "evenodd",
    d: "M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z",
    clipRule: "evenodd"
  }))))), __cssprop("div", {
    css: {
      "maxWidth": "94rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingTop": "0.75rem",
      "paddingBottom": "0.75rem",
      "paddingLeft": "0.5rem",
      "paddingRight": "0.5rem",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(229, 231, 235, var(--tw-text-opacity))"
      },
      "@media (min-width: 640px)": {
        "paddingLeft": "1rem",
        "paddingRight": "1rem"
      }
    }
  }, __cssprop(Link, {
    to: "#",
    css: {
      "display": "block",
      "borderRadius": "0.375rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500"
    }
  }, "Accounts"), __cssprop(Link, {
    to: "#",
    css: {
      "display": "block",
      "borderRadius": "0.375rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      },
      ".dark &": {
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))"
        }
      }
    }
  }, "Marketing"), __cssprop(Link, {
    to: "#",
    css: {
      "display": "block",
      "borderRadius": "0.375rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "1.25rem",
      "paddingRight": "0.75rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))"
        }
      },
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      }
    }
  }, "Update Marketing List"), __cssprop(Link, {
    to: "#",
    css: {
      "display": "block",
      "borderRadius": "0.375rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "1.25rem",
      "paddingRight": "0.75rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))"
        }
      },
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      }
    }
  }, "Order Assets"), __cssprop(Link, {
    to: "#",
    css: {
      "display": "block",
      "borderRadius": "0.375rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "1.25rem",
      "paddingRight": "0.75rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))"
        }
      },
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      }
    }
  }, "Media"), __cssprop(Link, {
    to: "#",
    css: {
      "display": "block",
      "borderRadius": "0.375rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      },
      ".dark &": {
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))"
        }
      }
    }
  }, "Contact")), __cssprop("div", {
    css: {
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      ".dark &": {
        "--tw-border-opacity": "1",
        "borderColor": "rgba(55, 65, 81, var(--tw-border-opacity))"
      },
      "paddingTop": "1rem",
      "paddingBottom": "0.75rem"
    }
  }, __cssprop("div", {
    css: {
      "maxWidth": "94rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "display": "flex",
      "alignItems": "center",
      "@media (min-width: 640px)": {
        "paddingLeft": "1.5rem",
        "paddingRight": "1.5rem"
      }
    }
  }, __cssprop("div", {
    css: {
      "display": "flex",
      "flexShrink": "0",
      "justifyContent": "center",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))"
      },
      "height": "2.5rem",
      "width": "2.5rem",
      "overflow": "hidden",
      "borderRadius": "9999px"
    }
  }, profile && profile.pictureUrl ? __cssprop("img", {
    css: {
      "height": "2.5rem",
      "width": "2.5rem",
      "borderRadius": "9999px"
    },
    src: profile.pictureUrl,
    alt: ""
  }) : __cssprop("svg", {
    "x-heroicon": "solid/user",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    css: {
      "height": "3rem",
      "width": "3rem",
      "paddingTop": "0.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(31, 41, 55, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(243, 244, 246, var(--tw-text-opacity))"
      }
    }
  }, __cssprop("path", {
    fillRule: "evenodd",
    d: "M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z",
    clipRule: "evenodd"
  }))), __cssprop("div", {
    css: {
      "marginLeft": "0.75rem",
      "minWidth": "0px",
      "flex": "1 1 0%"
    }
  }, __cssprop("div", {
    css: {
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(31, 41, 55, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(229, 231, 235, var(--tw-text-opacity))"
      },
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap"
    }
  }, profile && profile.name), __cssprop("div", {
    css: {
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      },
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap"
    }
  }, profile && profile.email)), __cssprop(Link, {
    to: "#",
    css: [{
      "marginRight": "-0.5rem",
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "borderRadius": "0.375rem",
      "--tw-text-opacity": "1",
      "color": "rgba(31, 41, 55, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))",
        ":focus": {
          "--tw-ring-offset-color": "#111827"
        }
      },
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-ring-offset-color": "#f3f4f6"
      }
    }, `padding: 5px;`]
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "View notifications"), __cssprop("svg", {
    "x-heroicon": "outline/bell",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    css: {
      "height": "1.5rem",
      "width": "1.5rem"
    }
  }, __cssprop("path", {
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2",
    d: "M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
  })))), __cssprop("div", {
    css: {
      "marginTop": "0.75rem",
      "maxWidth": "94rem",
      "marginLeft": "auto",
      "marginRight": "auto",
      "paddingLeft": "0.5rem",
      "paddingRight": "0.5rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.25rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.25rem * var(--tw-space-y-reverse))"
      },
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(229, 231, 235, var(--tw-text-opacity))"
      },
      "@media (min-width: 640px)": {
        "paddingLeft": "1rem",
        "paddingRight": "1rem"
      }
    }
  }, __cssprop(Link, {
    to: "#",
    css: {
      "display": "block",
      "borderRadius": "0.375rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      },
      ".dark &": {
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))"
        }
      }
    }
  }, "Your Profile"), __cssprop("button", {
    css: {
      "display": "block",
      "width": "100%",
      "textAlign": "left",
      "borderRadius": "0.375rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "paddingLeft": "0.75rem",
      "paddingRight": "0.75rem",
      "fontSize": "1rem",
      "lineHeight": "1.5rem",
      "fontWeight": "500",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
      },
      ".dark &": {
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))"
        }
      }
    },
    onClick: () => logout(),
    onKeyDown: () => logout()
  }, "Sign out"))))));
};

export default MobileMenu;