import React, { useState } from "../../_snowpack/pkg/react.js";
import { Link, useHistory, useLocation } from "../../_snowpack/pkg/react-router-dom.js";
import qs from "../../_snowpack/pkg/querystringify.js";
import useAuth from "../hooks/AuthHooks.js";
import Logo from "../images/Logo.js";
import { AuthWrapper } from "../components/Layout/index.js";
import { LabelStyle, InputStyle, FormStyle, ButtonStyle, LinkStyle, passwordPattern } from "../components/Layout/AuthWrapper.js";
import { jsx as __cssprop } from "../../_snowpack/pkg/@emotion/react.js";
const initialValues = {
  email: "",
  password1: "",
  password2: "",
  isSubmitting: false,
  errorMessage: ""
};
/**
 * Forgot password form
 *
 * @returns returns the forgot password form
 * @public
 */

const ResetPassword = () => {
  const location = useLocation();
  const {
    token,
    tokenId
  } = qs.parse(location.search);
  const history = useHistory();
  const resetPassword = useAuth(state => state.resetPassword);
  const requestResetPassword = useAuth(state => state.requestResetPassword);
  const hasRequestedReset = useAuth(state => state.hasRequestedReset);
  const [values, setValues] = useState(initialValues);
  const {
    email,
    password1,
    password2,
    isSubmitting,
    errorMessage
  } = values;

  const handleOnChange = event => setValues({ ...values,
    [event.target.name]: event.target.value
  });

  const handleOnSubmit = async event => {
    event.preventDefault();
    setValues({ ...values,
      isSubmitting: true
    });

    if (hasRequestedReset) {
      if (password1 !== password2) {
        return setValues({ ...values,
          isSubmitting: false,
          errorMessage: "Passwords do not match"
        });
      }

      if (typeof token !== "string" || typeof tokenId !== "string") {
        console.warn("Reset password tokens are invalid", {
          token,
          tokenId
        });
        return setValues({ ...values,
          isSubmitting: false,
          errorMessage: "Reset password tokens are invalid, check your email and try again."
        });
      }

      try {
        await resetPassword(token, tokenId, password1);
        setValues(initialValues);
        return history.push("/login");
      } catch (error) {
        console.warn(error);
        return setValues({ ...values,
          isSubmitting: false,
          errorMessage: error
        });
      }
    }

    await requestResetPassword(email);
    return setValues(initialValues);
  };

  return __cssprop(AuthWrapper, null, __cssprop("form", {
    onSubmit: handleOnSubmit,
    css: FormStyle
  }, __cssprop(Logo, {
    css: {
      "width": "75%",
      "marginLeft": "auto",
      "marginRight": "auto",
      "marginTop": "1rem",
      "marginBottom": "1.5rem",
      "--tw-text-opacity": "1",
      "color": "rgba(53, 62, 71, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(243, 244, 246, var(--tw-text-opacity))"
      }
    }
  }), __cssprop("label", {
    htmlFor: "email",
    css: LabelStyle,
    hidden: Boolean(hasRequestedReset)
  }, "EMAIL"), __cssprop("input", {
    css: InputStyle,
    hidden: Boolean(hasRequestedReset),
    type: "email",
    name: "email",
    id: "email",
    placeholder: "your@email.com",
    autoComplete: "email",
    value: email,
    onChange: handleOnChange,
    required: true
  }), hasRequestedReset && __cssprop(React.Fragment, null, __cssprop("label", {
    htmlFor: "password1",
    css: LabelStyle
  }, "PASSWORD"), __cssprop("input", {
    css: InputStyle,
    type: "password",
    name: "password1",
    id: "password1",
    placeholder: "\xA50uR_pa5sW\xBArD",
    pattern: passwordPattern,
    autoComplete: "current-password",
    value: password1,
    onChange: handleOnChange,
    required: true
  }), __cssprop("label", {
    htmlFor: "password2",
    css: LabelStyle
  }, "PASSWORD"), __cssprop("input", {
    css: InputStyle,
    type: "password",
    name: "password2",
    id: "password2",
    placeholder: "\xA50uR_pa5sW\xBArD",
    pattern: passwordPattern,
    autoComplete: "current-password",
    value: password2,
    onChange: handleOnChange,
    required: true
  })), errorMessage && __cssprop("span", {
    className: "form-error",
    css: {
      "marginTop": "-0.75rem",
      "fontSize": "0.75rem",
      "lineHeight": "1rem",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "--tw-text-opacity": "1",
      "color": "rgba(248, 113, 113, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))",
      "borderBottomLeftRadius": "0.25rem",
      "borderBottomRightRadius": "0.25rem",
      "borderWidth": "2px",
      "borderTopWidth": "0px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(31, 41, 55, var(--tw-border-opacity))"
    }
  }, errorMessage), __cssprop("button", {
    css: ButtonStyle,
    disabled: isSubmitting
  }, isSubmitting ? "Sending..." : "Submit"), __cssprop("div", {
    css: {
      "display": "inline-flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.5rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "alignItems": "center",
      "justifyContent": "center",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      },
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem"
    }
  }, __cssprop(Link, {
    to: "/",
    css: LinkStyle
  }, "Return to Login"), __cssprop("span", null, "|"), __cssprop(Link, {
    to: "/create-account",
    css: LinkStyle
  }, "Create Account"))));
};

export default ResetPassword;