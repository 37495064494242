import React from "../../_snowpack/pkg/react.js";
import { NavLink, Switch, Route, useRouteMatch } from "../../_snowpack/pkg/react-router-dom.js";
import { css, cx } from "../../_snowpack/pkg/@emotion/css.js";
import useAuth from "../hooks/AuthHooks.js";
import AccountList from "../components/Account/AccountList.js";
import UserCard from "../components/User/UserCard.js";
import UserSettings from "../components/User/UserSettings.js";
import TopNavBar from "../components/UI/TopNavBar.js";
import { BriefcaseIcon, CalendarIcon, CheckIcon, ChevronDownIcon, ChevronRightIcon, CurrencyDollarIcon, LinkIcon, LocationMarkerIcon, PencilIcon } from "../../_snowpack/pkg/@heroicons/react/solid.js";
import { SupportIcon, ArchiveIcon, UserCircleIcon } from "../../_snowpack/pkg/@heroicons/react/outline.js";
import { Menu, Transition } from "../../_snowpack/pkg/@headlessui/react.js";
import { jsx as __cssprop } from "../../_snowpack/pkg/@emotion/react.js";
export const Profile = () => {
  const profile = useAuth(state => state.profile);
  return __cssprop("main", {
    css: {
      "minWidth": "0px",
      "flex": "1 1 0%",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      ".dark &": {
        "--tw-border-opacity": "1",
        "borderColor": "rgba(55, 65, 81, var(--tw-border-opacity))"
      },
      "@media (min-width: 1024px)": {
        "display": "flex"
      }
    }
  }, __cssprop("section", {
    id: "primary-column",
    "aria-labelledby": "primary-heading",
    css: {
      "minWidth": "0px",
      "flex": "1 1 0%",
      "height": "100%",
      "display": "flex",
      "flexDirection": "column",
      "overflow": "hidden",
      "@media (min-width: 1024px)": {
        "order": "9999"
      }
    }
  }, __cssprop("h1", {
    id: "primary-heading",
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Portal"), __cssprop("h2", {
    css: {
      "fontFamily": "Flexo, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
      "fontWeight": "500",
      "fontSize": "1.25rem",
      "lineHeight": "1.75rem",
      "marginLeft": "1rem",
      "marginRight": "1rem",
      "marginTop": "1.5rem",
      "marginBottom": "1.5rem",
      "@media (min-width: 1024px)": {
        "display": "none"
      }
    }
  }, "Welcome ", profile && profile.name), __cssprop(UserCard, {
    css: {
      "@media (min-width: 1024px)": {
        "display": "none"
      }
    }
  }), __cssprop("h2", {
    css: {
      "fontFamily": "Flexo, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
      "fontWeight": "500",
      "fontSize": "1.25rem",
      "lineHeight": "1.75rem",
      "marginLeft": "1rem",
      "marginRight": "1rem",
      "marginTop": "1.5rem",
      "marginBottom": "1.5rem"
    }
  }, "Accounts"), __cssprop(AccountList, null)), __cssprop("aside", {
    css: {
      "display": "none",
      "@media (min-width: 1024px)": {
        "display": "block",
        "flexShrink": "0",
        "order": "-9999"
      }
    }
  }, __cssprop("div", {
    css: {
      "height": "100%",
      "position": "relative",
      "display": "flex",
      "flexDirection": "column",
      "width": "24rem",
      "borderRightWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      ".dark &": {
        "--tw-border-opacity": "1",
        "borderColor": "rgba(55, 65, 81, var(--tw-border-opacity))"
      }
    }
  }, __cssprop("h2", {
    css: {
      "fontFamily": "Flexo, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
      "fontWeight": "500",
      "fontSize": "1.25rem",
      "lineHeight": "1.75rem",
      "marginLeft": "1rem",
      "marginRight": "1rem",
      "marginTop": "1.5rem",
      "marginBottom": "1.5rem"
    }
  }, "Welcome ", profile && profile.name), __cssprop(UserCard, null))));
};
export const Marketing = () => {
  const files = [{
    title: "ProductImage_HR_Dolphin_M600_Left45Side",
    size: "1.09 MB",
    source: "//images.ctfassets.net/ftcnv5up9dxv/4JPYUN2nsnGDmINK5gIGLn/97cba4c99831d4ac515fa5f18daf2eef/ProductImage_HR_Dolphin_M600_Left45Side.png?fm=png&fl=png8&fit=pad&w=400"
  }, {
    title: "Dolphin-S-Line-S-300i-Up-45-Left-4K",
    size: "2.55 MB",
    source: "//res.cloudinary.com/maytronics-australia/image/upload/c_pad,fl_png8,g_center,w_400/v1570160091/S%20300i/Dolphin-S-Line-S-300i-Up-45-Left-4K_scfvrp.png"
  }, {
    title: "Dolphin-S-Line-S-300i-Front-45-Left-4K",
    size: "1.83 MB",
    source: "//res.cloudinary.com/maytronics-australia/image/upload/c_pad,fl_png8,g_center,w_400/v1570160091/S%20300i/Dolphin-S-Line-S-300i-Front-45-Left-4K_wi7zc7.png"
  }, {
    title: "Dolphin-S-Line-S-300i-Side-Left-4K",
    size: "7.11 MB",
    source: "//res.cloudinary.com/maytronics-australia/image/upload/c_pad,fl_png8,g_center,w_400/v1570160091/S%20300i/Dolphin-S-Line-S-300i-Side-Left-4K_ub2lud.png"
  } // More files...
  ];
  return __cssprop("main", {
    css: {
      "minWidth": "0px",
      "flex": "1 1 0%",
      "borderTopWidth": "1px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
      ".dark &": {
        "--tw-border-opacity": "1",
        "borderColor": "rgba(55, 65, 81, var(--tw-border-opacity))"
      },
      "padding": "0.5rem"
    }
  }, __cssprop("div", {
    css: {
      "@media (min-width: 1024px)": {
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between"
      }
    }
  }, __cssprop("div", {
    css: {
      "flex": "1 1 0%",
      "minWidth": "0px"
    }
  }, __cssprop("nav", {
    css: {
      "display": "flex"
    },
    "aria-label": "Breadcrumb"
  }, __cssprop("ol", {
    css: {
      "display": "flex",
      "alignItems": "center",
      "justifyContent": "center",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(1rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(1rem * calc(1 - var(--tw-space-x-reverse)))"
      }
    },
    role: "list"
  }, __cssprop("li", null, __cssprop("div", null, __cssprop(NavLink, {
    to: "/",
    css: {
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      },
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
        }
      }
    }
  }, "Portal"))), __cssprop("li", null, __cssprop(ChevronRightIcon, {
    css: {
      "display": "inline-flex",
      "flexShrink": "0",
      "height": "1.25rem",
      "width": "1.25rem",
      "marginRight": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }), __cssprop(NavLink, {
    to: "/marketing",
    css: {
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "600",
      "--tw-text-opacity": "1",
      "color": "rgba(75, 85, 99, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      },
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))",
        ":hover": {
          "--tw-text-opacity": "1",
          "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
        }
      }
    }
  }, "Marketing")))), __cssprop("h2", {
    css: {
      "marginTop": "0.5rem",
      "fontSize": "1.5rem",
      "lineHeight": "1.75rem",
      "fontWeight": "700",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
      },
      "@media (min-width: 640px)": {
        "fontSize": "1.875rem",
        "lineHeight": "2.25rem",
        "overflow": "hidden",
        "textOverflow": "ellipsis",
        "whiteSpace": "nowrap"
      }
    }
  }, "Marketing"), __cssprop("div", {
    css: {
      "marginTop": "0.25rem",
      "display": "flex",
      "flexDirection": "column",
      "@media (min-width: 640px)": {
        "flexDirection": "row",
        "flexWrap": "wrap",
        "marginTop": "0px",
        "> :not([hidden]) ~ :not([hidden])": {
          "--tw-space-x-reverse": 0,
          "marginRight": "calc(1.5rem * var(--tw-space-x-reverse))",
          "marginLeft": "calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))"
        }
      }
    }
  }, __cssprop("div", {
    css: {
      "marginTop": "0.5rem",
      "display": "flex",
      "alignItems": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      }
    }
  }, __cssprop(BriefcaseIcon, {
    css: {
      "flexShrink": "0",
      "marginRight": "0.375rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }), "Connect"), __cssprop("div", {
    css: {
      "marginTop": "0.5rem",
      "display": "flex",
      "alignItems": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      }
    }
  }, __cssprop(CalendarIcon, {
    css: {
      "flexShrink": "0",
      "marginRight": "0.375rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }), "Schedual"), __cssprop("div", {
    css: {
      "marginTop": "0.5rem",
      "display": "flex",
      "alignItems": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      }
    }
  }, __cssprop(LocationMarkerIcon, {
    css: {
      "flexShrink": "0",
      "marginRight": "0.375rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }), "Direct"), __cssprop("div", {
    css: {
      "marginTop": "0.5rem",
      "display": "flex",
      "alignItems": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      }
    }
  }, __cssprop(CurrencyDollarIcon, {
    css: {
      "flexShrink": "0",
      "marginRight": "0.375rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      }
    },
    "aria-hidden": "true"
  }), "Convert"))), __cssprop("div", {
    css: {
      "marginTop": "1.25rem",
      "display": "flex",
      "@media (min-width: 1024px)": {
        "marginTop": "0px",
        "marginLeft": "1rem"
      }
    }
  }, __cssprop("span", {
    css: {
      "display": "none",
      "@media (min-width: 640px)": {
        "display": "block"
      }
    }
  }, __cssprop("button", {
    type: "button",
    css: {
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "borderWidth": "1px",
      "borderColor": "transparent",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(55, 65, 81, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-offset-color": "#1f2937",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop(PencilIcon, {
    css: {
      "marginLeft": "-0.25rem",
      "marginRight": "0.5rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), "Edit")), __cssprop("span", {
    css: {
      "display": "none",
      "marginLeft": "0.75rem",
      "@media (min-width: 640px)": {
        "display": "block"
      }
    }
  }, __cssprop("button", {
    type: "button",
    css: {
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "borderWidth": "1px",
      "borderColor": "transparent",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(55, 65, 81, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-offset-color": "#1f2937",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, __cssprop(LinkIcon, {
    css: {
      "marginLeft": "-0.25rem",
      "marginRight": "0.5rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  }), "View")), __cssprop("span", {
    css: {
      "@media (min-width: 640px)": {
        "marginLeft": "0.75rem"
      }
    }
  }, __cssprop("button", {
    type: "button",
    css: {
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "borderWidth": "1px",
      "borderColor": "transparent",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(31, 41, 55, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(253, 181, 21, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "0.9",
        "--tw-text-opacity": "1",
        "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-offset-color": "#f3f4f6",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      },
      ".dark &": {
        ":focus": {
          "--tw-ring-offset-color": "#1f2937"
        }
      }
    }
  }, __cssprop(CheckIcon, {
    css: {
      "marginLeft": "-0.25rem",
      "marginRight": "0.5rem",
      "height": "1.25rem",
      "width": "1.25rem"
    },
    "aria-hidden": "true"
  }), "Publish")), __cssprop(Menu, {
    as: "span",
    css: {
      "marginLeft": "0.75rem",
      "position": "relative",
      "@media (min-width: 640px)": {
        "display": "none"
      }
    }
  }, ({
    open
  }) => __cssprop(React.Fragment, null, __cssprop(Menu.Button, {
    css: {
      "display": "inline-flex",
      "alignItems": "center",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "borderWidth": "1px",
      "borderColor": "transparent",
      "borderRadius": "0.375rem",
      "--tw-shadow": "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(55, 65, 81, var(--tw-bg-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-offset-color": "#1f2937",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(99, 102, 241, var(--tw-ring-opacity))"
      }
    }
  }, "More", __cssprop(ChevronDownIcon, {
    css: {
      "marginRight": "-0.25rem",
      "marginLeft": "0.5rem",
      "height": "1.25rem",
      "width": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
    },
    "aria-hidden": "true"
  })), __cssprop(Transition, {
    show: open,
    as: React.Fragment,
    enter: cx(css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
      "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
      "transitionDuration": "200ms"
    })),
    enterFrom: cx(css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": ".95",
      "--tw-scale-y": ".95",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "0"
    })),
    enterTo: cx(css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "1"
    })),
    leave: cx(css({
      "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1)",
      "transitionDuration": "75ms"
    })),
    leaveFrom: cx(css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": "1",
      "--tw-scale-y": "1",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "1"
    })),
    leaveTo: cx(css({
      "--tw-translate-x": "0",
      "--tw-translate-y": "0",
      "--tw-rotate": "0",
      "--tw-skew-x": "0",
      "--tw-skew-y": "0",
      "--tw-scale-x": ".95",
      "--tw-scale-y": ".95",
      "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
      "opacity": "0"
    }))
  }, __cssprop(Menu.Items, {
    static: true,
    css: {
      "transformOrigin": "top left",
      "position": "absolute",
      "left": "0px",
      "marginTop": "0.5rem",
      "marginLeft": "-0.25rem",
      "width": "12rem",
      "borderRadius": "0.375rem",
      "paddingTop": "0.25rem",
      "paddingBottom": "0.25rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-ring-opacity": "0.05",
      "--tw-ring-color": "rgba(0, 0, 0, var(--tw-ring-opacity))",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px"
      }
    }
  }, __cssprop(Menu.Item, null, ({
    active
  }) => __cssprop("a", {
    href: "#",
    css: css(active ? {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
    } : "", {
      "display": "block",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    })
  }, "Edit")), __cssprop(Menu.Item, null, ({
    active
  }) => __cssprop("a", {
    href: "#",
    css: css(active ? {
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))"
    } : "", {
      "display": "block",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))"
    })
  }, "View")))))))), __cssprop("ul", {
    role: "list",
    css: {
      "marginTop": "1.5rem",
      "marginBottom": "1.5rem",
      "display": "grid",
      "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
      "columnGap": "1rem",
      "rowGap": "2rem",
      "@media (min-width: 640px)": {
        "gridTemplateColumns": "repeat(3, minmax(0, 1fr))",
        "columnGap": "1.5rem"
      },
      "@media (min-width: 1024px)": {
        "gridTemplateColumns": "repeat(4, minmax(0, 1fr))"
      },
      "@media (min-width: 1280px)": {
        "columnGap": "2rem"
      }
    }
  }, files.map(file => __cssprop("li", {
    key: file.source,
    css: {
      "position": "relative"
    }
  }, __cssprop("div", {
    className: "group",
    css: {
      "display": "block",
      "width": "100%",
      "--tw-aspect-w": "10",
      "position": "relative",
      "paddingBottom": "calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%)",
      "> *": {
        "position": "absolute",
        "height": "100%",
        "width": "100%",
        "top": "0",
        "right": "0",
        "bottom": "0",
        "left": "0"
      },
      "--tw-aspect-h": "7",
      "borderRadius": "0.5rem",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(55, 65, 81, var(--tw-bg-opacity))",
        ":focus-within": {
          "--tw-ring-offset-color": "#1f2937"
        }
      },
      ":focus-within": {
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-offset-color": "#f3f4f6",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
      },
      "overflow": "hidden"
    }
  }, __cssprop("img", {
    src: file.source,
    alt: "",
    css: {
      "objectFit": "cover",
      "pointerEvents": "none",
      ".group:hover &": {
        "opacity": "0.75"
      }
    }
  }), __cssprop("button", {
    type: "button",
    css: {
      "position": "absolute",
      "top": "0px",
      "right": "0px",
      "bottom": "0px",
      "left": "0px",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px"
      }
    }
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "View details for ", file.title))), __cssprop("p", {
    css: {
      "marginTop": "0.5rem",
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(243, 244, 246, var(--tw-text-opacity))"
      },
      "overflow": "hidden",
      "textOverflow": "ellipsis",
      "whiteSpace": "nowrap",
      "pointerEvents": "none"
    }
  }, file.title), __cssprop("p", {
    css: {
      "display": "block",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "fontWeight": "500",
      "--tw-text-opacity": "1",
      "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(156, 163, 175, var(--tw-text-opacity))"
      },
      "pointerEvents": "none"
    }
  }, file.size)))));
};
/**
 * Portal interface / dashboard
 *
 * @returns returns the interface displaying a users profile data
 */

export const Portal = () => {
  const match = useRouteMatch();
  return __cssprop("div", {
    css: {
      "display": "flex",
      "flexDirection": "column",
      "height": "100vh",
      "width": "100%",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(243, 244, 246, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgba(229, 231, 235, var(--tw-text-opacity))"
      },
      "--tw-text-opacity": "1",
      "color": "rgba(31, 41, 55, var(--tw-text-opacity))"
    }
  }, __cssprop(TopNavBar, null), __cssprop("div", {
    css: {
      "minHeight": "0px",
      "flex": "1 1 0%",
      "display": "flex"
    }
  }, __cssprop("nav", {
    "aria-label": "Sidebar",
    css: {
      "display": "none",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(229, 231, 235, var(--tw-bg-opacity))"
      },
      "@media (min-width: 768px)": {
        "display": "block",
        "flexShrink": "0",
        "overflowY": "auto"
      }
    }
  }, __cssprop("div", {
    css: {
      "position": "relative",
      "width": "5rem",
      "display": "flex",
      "flexDirection": "column",
      "padding": "0.75rem",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-y-reverse": 0,
        "marginTop": "calc(0.75rem * calc(1 - var(--tw-space-y-reverse)))",
        "marginBottom": "calc(0.75rem * var(--tw-space-y-reverse))"
      }
    }
  }, __cssprop(NavLink, {
    to: match.url,
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))"
        }
      },
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(55, 65, 81, var(--tw-bg-opacity))"
      },
      "flexShrink": "0",
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "height": "3.5rem",
      "width": "3.5rem",
      "borderRadius": "0.5rem"
    },
    activeClassName: cx(css({
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgba(31, 41, 55, var(--tw-text-opacity))"
      },
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
    })),
    isActive: match => Boolean(match && match.url === "/"),
    exact: true
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Account"), __cssprop(UserCircleIcon, {
    "aria-hidden": "true",
    css: {
      "height": "1.5rem",
      "width": "1.5rem"
    }
  })), __cssprop(NavLink, {
    to: `/marketing`,
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))"
        }
      },
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(55, 65, 81, var(--tw-bg-opacity))"
      },
      "flexShrink": "0",
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "height": "3.5rem",
      "width": "3.5rem",
      "borderRadius": "0.5rem"
    },
    activeClassName: cx(css({
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgba(31, 41, 55, var(--tw-text-opacity))"
      },
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
    }))
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Marketing"), __cssprop(ArchiveIcon, {
    "aria-hidden": "true",
    css: {
      "height": "1.5rem",
      "width": "1.5rem"
    }
  })), __cssprop(NavLink, {
    to: `/support`,
    css: {
      "--tw-text-opacity": "1",
      "color": "rgba(156, 163, 175, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(107, 114, 128, var(--tw-text-opacity))",
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))"
        }
      },
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(55, 65, 81, var(--tw-bg-opacity))"
      },
      "flexShrink": "0",
      "display": "inline-flex",
      "alignItems": "center",
      "justifyContent": "center",
      "height": "3.5rem",
      "width": "3.5rem",
      "borderRadius": "0.5rem"
    },
    activeClassName: cx(css({
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgba(31, 41, 55, var(--tw-text-opacity))"
      },
      "--tw-text-opacity": "1",
      "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
    }))
  }, __cssprop("span", {
    css: {
      "position": "absolute",
      "width": "1px",
      "height": "1px",
      "padding": "0",
      "margin": "-1px",
      "overflow": "hidden",
      "clip": "rect(0, 0, 0, 0)",
      "whiteSpace": "nowrap",
      "borderWidth": "0"
    }
  }, "Support"), __cssprop(SupportIcon, {
    "aria-hidden": "true",
    css: {
      "height": "1.5rem",
      "width": "1.5rem"
    }
  })))), __cssprop(Switch, null, __cssprop(Route, {
    path: `/`,
    exact: true
  }, __cssprop(Profile, null)), __cssprop(Route, {
    path: `/profile`,
    exact: true
  }, __cssprop(UserSettings, null)), __cssprop(Route, {
    path: `/marketing`
  }, __cssprop(Marketing, null)), __cssprop(Route, {
    path: `/support`
  }, __cssprop("div", null, "Nothing to see here")))));
};
export default Portal;