import React from "../../_snowpack/pkg/react.js";
import { Route, Redirect } from "../../_snowpack/pkg/react-router-dom.js";
import useAuth from "../hooks/AuthHooks.js";
/**
 * Private route protects app from unauthenticated users
 *
 * @returns {JSX.Element} a route to portal, else redirect to login
 */

import { jsx as __cssprop } from "../../_snowpack/pkg/@emotion/react.js";

const PrivateRoute = ({
  path,
  component
}) => {
  /** useAuth to get the auth property isAuthenticated */
  const isAuthenticated = useAuth(state => state.isAuthenticated); // detruct the auth state from Auth react hook

  return isAuthenticated ? __cssprop(Route, {
    path: path,
    component: component
  }) : __cssprop(Redirect, {
    to: "/login"
  });
};

export default PrivateRoute;