import create from "../../_snowpack/pkg/zustand.js";
import { devtools } from "../../_snowpack/pkg/zustand/middleware.js";
import * as t from "../../_snowpack/pkg/io-ts.js";
const YINGYANG = t.keyof({
  dark: null,
  light: null
});

/**
 * Global UI State Store
 *
 * Now controling: Mobile, Theme and Search
 *
 * @param {UIState} state of the UI
 * @returns {UseStore<UIState>} useUI hook
 * @public
 * @see ZUSTAND 🐻 https://github.com/pmndrs/zustand
 */
const useUI = create(devtools(set => {
  const isDark = localStorage.theme === "dark" || !("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches;
  if (isDark) localStorage.setItem("theme", isDark ? "dark" : "light");
  return {
    theme: isDark ? "dark" : "light",
    toggleDark: () => {
      set(state => {
        const theme = state.theme === "dark" ? "light" : "dark";
        localStorage.setItem("theme", theme);
        return {
          theme
        };
      });
    },
    isMobile: false,
    toggleMobile: () => set(state => ({
      isMobile: !state.isMobile
    })),
    closeMobile: () => set({
      isMobile: false
    }),
    isSearch: false,
    toggleSearch: () => set(state => ({
      isSearch: !state.isSearch
    })),
    closeSearch: () => set({
      isSearch: false
    })
  };
}));
export default useUI;