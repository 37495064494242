import React, { useState } from "../../_snowpack/pkg/react.js";
import { Link, useHistory, useLocation } from "../../_snowpack/pkg/react-router-dom.js";
import { Helmet } from "../../_snowpack/pkg/react-helmet.js";
import * as Realm from "../../_snowpack/pkg/realm-web.js";
import useAuth from "../hooks/AuthHooks.js";
import Logo from "../images/Logo.js";
import { AuthWrapper } from "../components/Layout/index.js";
import { LabelStyle, InputStyle, FormStyle, passwordPattern, ButtonStyle, LinkStyle } from "../components/Layout/AuthWrapper.js";
import { jsx as __cssprop } from "../../_snowpack/pkg/@emotion/react.js";
const USERNAME = "";
const PASSWORD = "";
/**
 * User login form
 * @public
 */

const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const app = useAuth(state => state.app);
  const login = useAuth(state => state.login);
  const [values, setValues] = useState({
    email: USERNAME,
    password: PASSWORD,
    isSubmitting: false,
    errorMessage: ""
  });
  const {
    email,
    password,
    isSubmitting,
    errorMessage
  } = values;

  const handleOnChange = event => setValues({ ...values,
    [event.target.name]: event.target.value
  });

  const handleOnSubmit = async event => {
    event.preventDefault();
    setValues({ ...values,
      isSubmitting: true
    });
    const user = await login(email, password);

    if (user === undefined) {
      setValues({ ...values,
        isSubmitting: false,
        errorMessage: "Something went wrong check your details and try again"
      });
      return history.push("#");
    }

    setValues({ ...values,
      isSubmitting: false
    });
    return history.push("/");
  };

  React.useEffect(() => {
    location.hash && Realm.handleAuthRedirect();
  }, []);
  return __cssprop(AuthWrapper, null, __cssprop(Helmet, null, __cssprop("title", null, "Marketing Portal - Login"), __cssprop("meta", {
    name: "description",
    content: "Login to the Maytronics Marketing Portal"
  })), __cssprop("form", {
    onSubmit: handleOnSubmit,
    css: FormStyle
  }, __cssprop(Logo, {
    css: {
      "width": "75%",
      "marginLeft": "auto",
      "marginRight": "auto",
      "marginTop": "1rem",
      "marginBottom": "1.5rem",
      "--tw-text-opacity": "1",
      "color": "rgba(53, 62, 71, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(243, 244, 246, var(--tw-text-opacity))"
      }
    }
  }), __cssprop("div", {
    tabIndex: 0,
    role: "button",
    onClick: async () => {
      const user = await login("", "", "http://localhost:8080/login", "facebook");
      user && history.push("/");
    },
    css: {
      "textAlign": "center",
      "fontFamily": "Flexo, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "marginTop": "0.25rem",
      "marginBottom": "0.25rem",
      "width": "100%",
      "maxWidth": "100%",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(59, 130, 246, var(--tw-bg-opacity))",
      ".dark &": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(37, 99, 235, var(--tw-bg-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgba(243, 244, 246, var(--tw-text-opacity))",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(59, 130, 246, var(--tw-border-opacity))",
        ":hover": {
          "--tw-bg-opacity": "1",
          "backgroundColor": "rgba(59, 130, 246, var(--tw-bg-opacity))",
          "--tw-text-opacity": "1",
          "color": "rgba(249, 250, 251, var(--tw-text-opacity))"
        },
        ":focus": {
          "--tw-border-opacity": "1",
          "borderColor": "rgba(31, 41, 55, var(--tw-border-opacity))"
        }
      },
      "--tw-text-opacity": "1",
      "color": "rgba(249, 250, 251, var(--tw-text-opacity))",
      "borderRadius": "0.25rem",
      "borderWidth": "2px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(96, 165, 250, var(--tw-border-opacity))",
      ":hover": {
        "--tw-bg-opacity": "1",
        "backgroundColor": "rgba(96, 165, 250, var(--tw-bg-opacity))",
        "--tw-text-opacity": "1",
        "color": "rgba(255, 255, 255, var(--tw-text-opacity))"
      },
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(59, 130, 246, var(--tw-ring-opacity))"
      }
    }
  }, "Login with Facebook"), __cssprop("p", {
    css: [{
      "position": "relative",
      "textAlign": "center",
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem",
      "marginTop": "0.5rem",
      "marginBottom": "0.5rem"
    }, {
      ":before": {
        "--tw-translate-x": "-100%",
        "--tw-translate-y": "50%",
        "--tw-rotate": "0",
        "--tw-skew-x": "0",
        "--tw-skew-y": "0",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
        "display": "inline-block",
        "top": "0px",
        "right": "0px",
        "bottom": "0px",
        "left": "0px",
        "borderTopWidth": "1px",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(156, 163, 175, var(--tw-border-opacity))",
        ".dark &": {
          "--tw-border-opacity": "1",
          "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))"
        },
        "content": "\"\"",
        "margin": "auto",
        "height": "0px",
        "position": "absolute",
        "width": "33.333333%"
      }
    }, {
      ":after": {
        "--tw-translate-x": "100%",
        "--tw-translate-y": "50%",
        "--tw-rotate": "0",
        "--tw-skew-x": "0",
        "--tw-skew-y": "0",
        "--tw-scale-x": "1",
        "--tw-scale-y": "1",
        "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
        "display": "inline-block",
        "top": "0px",
        "right": "0px",
        "bottom": "0px",
        "left": "0px",
        "borderTopWidth": "1px",
        "--tw-border-opacity": "1",
        "borderColor": "rgba(156, 163, 175, var(--tw-border-opacity))",
        ".dark &": {
          "--tw-border-opacity": "1",
          "borderColor": "rgba(229, 231, 235, var(--tw-border-opacity))"
        },
        "content": "\"\"",
        "margin": "auto",
        "height": "0px",
        "position": "absolute",
        "width": "33.333333%"
      }
    }]
  }, "OR"), __cssprop("label", {
    htmlFor: "email",
    css: LabelStyle
  }, "USERNAME"), __cssprop("input", {
    css: InputStyle,
    type: "email",
    name: "email",
    id: "email",
    placeholder: "your@email.com",
    autoComplete: "email",
    value: email,
    onChange: handleOnChange,
    required: true
  }), __cssprop("label", {
    htmlFor: "password",
    css: LabelStyle
  }, "PASSWORD"), __cssprop("input", {
    css: InputStyle,
    type: "password",
    name: "password",
    id: "password",
    placeholder: "\xA50uR_pa5sW\xBArD",
    pattern: passwordPattern.toString(),
    autoComplete: "current-password",
    value: password,
    onChange: handleOnChange,
    required: true
  }), errorMessage && __cssprop("span", {
    className: "form-error",
    css: {
      "marginTop": "-0.75rem",
      "fontSize": "0.75rem",
      "lineHeight": "1rem",
      "paddingLeft": "1rem",
      "paddingRight": "1rem",
      "paddingTop": "0.5rem",
      "paddingBottom": "0.5rem",
      "--tw-text-opacity": "1",
      "color": "rgba(248, 113, 113, var(--tw-text-opacity))",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(31, 41, 55, var(--tw-bg-opacity))",
      "borderBottomLeftRadius": "0.25rem",
      "borderBottomRightRadius": "0.25rem",
      "borderWidth": "2px",
      "borderTopWidth": "0px",
      "--tw-border-opacity": "1",
      "borderColor": "rgba(31, 41, 55, var(--tw-border-opacity))"
    }
  }, errorMessage), __cssprop("button", {
    type: "submit",
    css: ButtonStyle,
    disabled: isSubmitting
  }, isSubmitting ? "Signing In..." : "Sign In"), __cssprop("div", {
    css: {
      "display": "inline-flex",
      "> :not([hidden]) ~ :not([hidden])": {
        "--tw-space-x-reverse": 0,
        "marginRight": "calc(0.5rem * var(--tw-space-x-reverse))",
        "marginLeft": "calc(0.5rem * calc(1 - var(--tw-space-x-reverse)))"
      },
      "alignItems": "center",
      "justifyContent": "center",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(209, 213, 219, var(--tw-text-opacity))"
      },
      "fontSize": "0.875rem",
      "lineHeight": "1.25rem"
    }
  }, __cssprop(Link, {
    to: "/reset-password",
    css: LinkStyle
  }, "Forgot Password"), __cssprop("span", null, "|"), __cssprop(Link, {
    to: "/create-account",
    css: LinkStyle
  }, "Create Account"))));
};

export default Login;