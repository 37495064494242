import _isEmpty from "../../../_snowpack/pkg/ramda/es/isEmpty.js";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../_snowpack/pkg/@emotion/styled.js";
import React, { useEffect } from "../../../_snowpack/pkg/react.js";
import useAuth from "../../hooks/AuthHooks.js";
import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const Item = _styled.li({
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))",
  ".dark &": {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))"
  },
  "overflow": "hidden",
  "@media (min-width: 640px)": {
    "borderRadius": "0.5rem",
    "--tw-shadow": "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    "boxShadow": "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)"
  }
});

const OutlinePlusCircle = props => __cssprop("svg", _extends({
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}, props), __cssprop("path", {
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2,
  d: "M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
}));

const OutlinePencil = props => __cssprop("svg", _extends({
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}, props), __cssprop("path", {
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeWidth: 2,
  d: "M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
}));

const SolidMail = props => __cssprop("svg", _extends({
  xmlns: "http://www.w3.org/2000/svg",
  stroke: "none",
  viewBox: "0 0 24 24",
  fill: "currentColor"
}, props), __cssprop("path", {
  d: "M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
}), __cssprop("path", {
  d: "M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
}));

const SolidUser = props => __cssprop("svg", _extends({
  xmlns: "http://www.w3.org/2000/svg",
  stroke: "none",
  viewBox: "0 0 24 24",
  fill: "currentColor"
}, props), __cssprop("path", {
  fillRule: "evenodd",
  d: "M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z",
  clipRule: "evenodd"
}));

const SolidUsers = props => __cssprop("svg", _extends({
  xmlns: "http://www.w3.org/2000/svg",
  stroke: "none",
  viewBox: "0 0 24 24",
  fill: "currentColor"
}, props), __cssprop("path", {
  d: "M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
}));

const SolidPhotograph = props => __cssprop("svg", _extends({
  xmlns: "http://www.w3.org/2000/svg",
  stroke: "none",
  viewBox: "0 0 24 24",
  fill: "currentColor"
}, props), __cssprop("path", {
  fillRule: "evenodd",
  d: "M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z",
  clipRule: "evenodd"
}));

const AddItem = ({
  description
}) => {
  return __cssprop("li", {
    role: "button",
    css: {
      "display": "inline-flex",
      "alignItems": "center",
      "width": "100%",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "0.9"
      },
      "marginBottom": "1rem",
      "cursor": "pointer"
    }
  }, __cssprop(OutlinePlusCircle, {
    css: {
      "width": "1.5rem",
      "height": "1.5rem",
      "marginRight": "1rem"
    }
  }), __cssprop("span", null, description));
};

const EditItem = ({
  description,
  icon
}) => {
  return __cssprop("li", {
    css: {
      "display": "inline-flex",
      "alignItems": "center",
      "width": "100%",
      "--tw-text-opacity": "1",
      "color": "rgba(55, 65, 81, var(--tw-text-opacity))",
      ".dark &": {
        "--tw-text-opacity": "1",
        "color": "rgba(229, 231, 235, var(--tw-text-opacity))"
      },
      "marginBottom": "1rem"
    }
  }, icon === "mail" && __cssprop(SolidMail, {
    css: {
      "height": "1.25rem",
      "width": "1.25rem",
      "marginLeft": "0.125rem",
      "marginRight": "1rem"
    }
  }), icon === "user" && __cssprop(SolidUser, {
    css: {
      "height": "1.25rem",
      "width": "1.25rem",
      "marginLeft": "0.125rem",
      "marginRight": "1rem"
    }
  }), icon === "users" && __cssprop(SolidUsers, {
    css: {
      "height": "1.25rem",
      "width": "1.25rem",
      "marginLeft": "0.125rem",
      "marginRight": "1rem"
    }
  }), icon === "photograph" && __cssprop(SolidPhotograph, {
    css: {
      "height": "1.25rem",
      "width": "1.25rem",
      "marginLeft": "0.125rem",
      "marginRight": "1rem"
    }
  }), __cssprop("span", null, description), __cssprop("button", {
    onClick: () => ({}),
    css: {
      "marginLeft": "auto",
      "--tw-text-opacity": "1",
      "color": "rgba(88, 200, 223, var(--tw-text-opacity))",
      ":hover": {
        "--tw-text-opacity": "0.9"
      },
      "borderRadius": "9999px",
      ":focus": {
        "outline": "2px solid transparent",
        "outlineOffset": "2px",
        "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
        "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
        "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
        "--tw-ring-offset-width": "2px",
        "--tw-ring-opacity": "1",
        "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))",
        "--tw-ring-offset-color": "#f3f4f6"
      },
      ".dark &": {
        ":focus": {
          "--tw-ring-offset-color": "#111827"
        }
      }
    }
  }, __cssprop(OutlinePencil, {
    css: {
      "width": "1.5rem",
      "height": "1.5rem"
    }
  })));
};

const UserCard = props => {
  const user = useAuth(state => state.user);
  const profile = useAuth(state => state.profile);
  const updateProfile = useAuth(state => state.updateProfile);
  const noProfile = profile === null;
  useEffect(() => {
    const setEmail = async () => {
      if (noProfile && user && !_isEmpty(user.profile.email)) {
        return await updateProfile({
          email: user?.profile.email || ""
        });
      }

      return undefined;
    };

    setEmail();
  }, []);
  return __cssprop("ul", _extends({
    id: "user",
    css: {
      "marginLeft": "1rem",
      "marginRight": "1rem"
    }
  }, props), profile && profile.email ? __cssprop(EditItem, {
    description: profile.email,
    icon: "mail"
  }) : __cssprop(AddItem, {
    description: "Add a email"
  }), profile && profile.name ? __cssprop(EditItem, {
    description: profile.name,
    icon: "user"
  }) : __cssprop(AddItem, {
    description: "Add a name"
  }), profile && profile.pictureUrl ? __cssprop(EditItem, {
    description: profile.pictureUrl,
    icon: "photograph"
  }) : __cssprop(AddItem, {
    description: "Add a profile image"
  }), __cssprop(AddItem, {
    description: "Add an account"
  }));
};

export default UserCard;