import React from "../_snowpack/pkg/react.js";
import { BrowserRouter as Router, Switch, Route } from "../_snowpack/pkg/react-router-dom.js";
import PrivateRoute from "./utils/PrivateRoute.js";
import useUI from "./hooks/UIHooks.js";
import Portal from "./pages/Portal.js";
import Login from "./pages/Login.js";
import ResetPassword from "./pages/ResetPassword.js";
import CreateAccount from "./pages/CreateAccount.js";
/**
 * Application wrapper for the router switch
 *
 * If the user isn't authorised via private route then they will be redirected
 * to the login route. The portal contains the nested routes for the main app.
 *
 * @returns {JSX.Element} the App JSX Element.
 */

import { jsx as __cssprop } from "../_snowpack/pkg/@emotion/react.js";

const App = () => {
  /** useUI to get the UI theme property isDark */
  const theme = useUI(state => state.theme); // select state slice of theme

  return __cssprop(Router, null, __cssprop("div", {
    className: theme
  }, __cssprop("div", {
    className: "App"
  }, __cssprop(Switch, null, __cssprop(Route, {
    path: "/login",
    component: Login
  }), __cssprop(Route, {
    path: "/reset-password",
    component: ResetPassword
  }), __cssprop(Route, {
    path: "/create-account",
    component: CreateAccount
  }), __cssprop(PrivateRoute, {
    path: "/",
    component: Portal
  })))));
};

export default App;