function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import _styled from "../../../_snowpack/pkg/@emotion/styled.js";
import React from "../../../_snowpack/pkg/react.js";
import useUI from "../../hooks/UIHooks.js";
/**
 * Dark Mode Switch (Toggle)
 *
 * Welcome to the dark side, switch theme toggle from light to dark
 *
 * @returns {JSX.Element} button: switch: toggle
 */

import { jsx as __cssprop } from "../../../_snowpack/pkg/@emotion/react.js";

const ToggleDark = props => {
  const theme = useUI(state => state.theme);
  const isDark = theme === "dark";
  const toggleDark = useUI(state => state.toggleDark);
  const Switch = _styled.button`
		${{
    "position": "relative",
    "display": "inline-flex",
    "flexShrink": "0",
    "height": "1.5rem",
    "width": "2.75rem",
    "borderWidth": "2px",
    "borderColor": "transparent",
    "borderRadius": "9999px",
    "cursor": "pointer",
    "transitionProperty": "background-color, border-color, color, fill, stroke",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "200ms",
    ":focus": {
      "outline": "2px solid transparent",
      "outlineOffset": "2px",
      "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
      "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
      "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)",
      "--tw-ring-offset-width": "2px",
      "--tw-ring-opacity": "1",
      "--tw-ring-color": "rgba(88, 200, 223, var(--tw-ring-opacity))"
    }
  }}
		${theme === "dark" ? {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(75, 85, 99, var(--tw-bg-opacity))",
    ":focus": {
      "--tw-ring-offset-color": "#111827"
    }
  } : {
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgba(209, 213, 219, var(--tw-bg-opacity))",
    ":focus": {
      "--tw-ring-offset-color": "#f3f4f6"
    }
  }}
	`;

  const ScreenReader = _styled.span({
    "position": "absolute",
    "width": "1px",
    "height": "1px",
    "padding": "0",
    "margin": "-1px",
    "overflow": "hidden",
    "clip": "rect(0, 0, 0, 0)",
    "whiteSpace": "nowrap",
    "borderWidth": "0"
  });

  const Toggle = _styled.span({
    "--tw-translate-x": "1.25rem",
    "--tw-translate-y": "0",
    "--tw-rotate": "0",
    "--tw-skew-x": "0",
    "--tw-skew-y": "0",
    "--tw-scale-x": "1",
    "--tw-scale-y": "1",
    "transform": "translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "transitionProperty": "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "200ms",
    "pointerEvents": "none",
    "position": "relative",
    "display": "inline-block",
    "height": "1.25rem",
    "width": "1.25rem",
    "borderRadius": "9999px",
    "--tw-ring-offset-shadow": "var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)",
    "--tw-ring-shadow": "var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color)",
    "boxShadow": "var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)"
  });

  const Icon = _styled.span({
    "position": "absolute",
    "top": "0px",
    "right": "0px",
    "bottom": "0px",
    "left": "0px",
    "height": "100%",
    "width": "100%",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "transitionProperty": "opacity",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "150ms"
  });

  return __cssprop(Switch, _extends({
    id: "dark-mode-switcher",
    as: "button",
    role: "switch",
    tabIndex: 0,
    "aria-checked": isDark,
    onClick: () => toggleDark(),
    title: theme + " mode"
  }, props), __cssprop(ScreenReader, {
    "aria-readonly": true
  }, "Switch dark mode " + (isDark ? "OFF" : "ON")), __cssprop(Toggle, {
    "aria-hidden": "true",
    css: isDark ? {
      "--tw-translate-x": "1.25rem !important",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(17, 24, 39, var(--tw-bg-opacity))"
    } : {
      "--tw-translate-x": "0px !important",
      "--tw-bg-opacity": "1",
      "backgroundColor": "rgba(255, 255, 255, var(--tw-bg-opacity))"
    }
  }, __cssprop(Icon, {
    css: [{
      "opacity": "0",
      "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1)",
      "transitionDuration": "100ms"
    }, isDark ? {
      "opacity": "0 !important",
      "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1) !important",
      "transitionDuration": "100ms !important"
    } : {
      "opacity": "1 !important",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1)",
      "transitionDuration": "200ms !important"
    }]
  }, __cssprop("svg", {
    "x-heroicon": "solid/sun",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    css: {
      "height": "1rem",
      "width": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgba(17, 24, 39, var(--tw-text-opacity))"
    }
  }, __cssprop("path", {
    fillRule: "evenodd",
    d: "M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z",
    clipRule: "evenodd"
  }))), __cssprop(Icon, {
    css: [{
      "opacity": "1",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1)",
      "transitionDuration": "200ms"
    }, isDark ? {
      "opacity": "1 !important",
      "transitionTimingFunction": "cubic-bezier(0.4, 0, 1, 1) !important",
      "transitionDuration": "200ms !important"
    } : {
      "opacity": "0 !important",
      "transitionTimingFunction": "cubic-bezier(0, 0, 0.2, 1) !important",
      "transitionDuration": "100ms !important"
    }]
  }, __cssprop("svg", {
    "x-heroicon": "solid/moon",
    xmlns: "http://www.w3.org/2000/svg",
    viewBox: "0 0 20 20",
    fill: "currentColor",
    css: {
      "height": "1rem",
      "width": "1rem",
      "--tw-text-opacity": "1",
      "color": "rgba(243, 244, 246, var(--tw-text-opacity))"
    }
  }, __cssprop("path", {
    d: "M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
  })))));
};

export default ToggleDark;